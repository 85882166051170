.vip-event-box {
    background-color: #001A3D;
}

.vip-event-search-width {
    display: block;
}

.vip-event-company-summary-title {
    margin: 2px 0 20px 30px;
}

.vip-event-myincome-title {
    margin: 10px 0 20px 30px;
}

.vip-event-drop {
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
	max-width: 100px;
	margin: auto;
    margin-bottom: 15px;
}

.vip-event-drop .img2 {
	display: none;
}

.vip-event-drop[aria-expanded="true"] .img1 {
	display: none;
}

.vip-event-drop[aria-expanded="true"] .img2 {
	display: block;
}

.href-decoration {
    text-decoration:underline !important; 
}

.summary-downline-title {
    margin: 20px 0 20px 20px;
}

.shareholder-accordion-details {
    display: flex;
	justify-content: space-between;
}

.shareholder-template-box {
    background-color: #02214A;
    padding: 0px 23px 15px 23px;
    border-radius: 0px 0px 8px 8px;
    margin: 20px 0px;
    margin-top: -4px;
}

.shareholder-boxes {
    background-color: #02214A;
    display: flex;
    border-radius: 8px;
    justify-content: space-between;
    height: 60px;
    align-items: center;
    cursor: pointer;
}

.detail-value {
    font-family: 'averta-thin';
    align-items: center;
    min-width: 150px;
    justify-content: center;
    text-align: center;
}

.red-btn {
    background: linear-gradient(180deg, rgba(255, 110, 144, 1) 0%, rgba(253, 59, 90, 1) 100%);
    color: #001A3D;
    border: 1px solid #FF6E90;
}

.status-claim {
    margin:auto;
}

.text-status {
    font-size:10px;
}

.current-summary-template-box {
    padding: 16px 25px;
    background-color: #02214A;
    border-radius: 8px;
}

.vip-event-claim-title {
    margin: 20px 0 20px 25px;
}

.hide-summary-radio-btn {
    margin: 15px 21px 0 0;
}

.form-white-input-percent {
    padding: 0px 20px 0px 20px;
	/* font-size: 11px; */
	border-radius: 20px;
	background: #02214A;
	border: 1px solid #fff;
	color: #fff;
	width: 100%;
	height: 32px;
    display: flex;
    text-align: center;
    align-items: center;
    min-width: 150px;
    justify-content: center;

}

.shareholder-btn {
    display: flex;
    align-items: center;
    min-width: 150px;
    justify-content: center;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    max-width: 150px;
    padding: 8px 20px;
}

.shareholder-save-btn {
    width: 100%;
    display: block;
    text-align: center;
    width: 150px;
    background: linear-gradient(180deg, rgb(252 235 55) 0%, rgba(253,182,46,1) 56%, rgba(254,126,37,1) 100%);
}

.yes-no-button {
    width: 150px;
    padding: 8px 20px;
}

.collapse-width {
    max-width: 60px;
}

.company-summary-grand-total {
    max-width: 100px;
    margin: auto;
    text-align: left !important;
}

.mi_game_list {
    max-width: 60px;
    margin: auto;
    text-align: left !important;
}

.left-date {
    margin-left: 10%;
}

.right-date {
    margin-right: 10%;
}

.vip-event-scroll {
    white-space: nowrap;  
    overflow-x: auto;
    overflow-y: hidden;
}

/* help-centre */
.help-centre-banner-container {
    position: relative;
    text-align: center;
    background-color: #02214A;
}

#help-centre-banner .img-100 {
    opacity: 0.5;
}

#help-centre-banner{
    margin-top: 22px;
}

.help-centre-search-wrap{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.help-centre-title {
    font-size: 25px;
    margin-bottom:25px;
}

.help-centre-search-bar{
    font-size: 13px;
}

.help-centre-search-input::placeholder {
    color: #1F1F1F;
    opacity: 0.54;
}

.help-centre-search {
    display: flex;
    min-width: 400px;
    min-height: 45px;
}

.help-centre-search-input {
    border: 1px solid #FFFFFF;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    font-weight: 300;
    padding-left: 10px;
    border-right: 0px;
    width: 81%;
    font-size: 14px;
    padding-right: 5px;
    background: #FFFFFF;
    font-family: 'averta-semibold';
}

.help-centre-search-btn {
    border: 1px solid #FFFFFF ;
    border-bottom-left-radius: 30px;
    border-top-left-radius: 30px;
    display: inline-block;
    padding: 14px 25px !important;
    vertical-align: middle;
    border-left: 0px;
    width: 19%;
    background: #FFFFFF ;
}

.help-centre-margin {
    margin-bottom: 20px;
}

.help-centre-side-list-margin{
    margin-bottom: 20px;
    margin-left: 20px;

}

.help-centre-list:hover {
    background: #062B5E;
    /* cursor: pointer; */
}

.help-centre-first-list:hover {
    background: #062B5E;
    /* cursor: pointer; */
}

.help-centre-list{
    /* width: 300px; */
    padding: 20px 32px;
    border-radius: 20px;
    background-color: #001A3D;
    height: 100%;
    margin: auto;
}

.help-centre-list-title{
    font-size: 16px;
    margin-bottom: 20px;
}

.help-centre-list-item{
    font-size: 14px;
    margin-bottom: 10px;
}

.help-centre-icon{
    width: 35px;
    height: 35px;
}

.help-centre-list-container{
    padding-top: 39px;
    background-color: #02214A;
    padding-bottom: 38px;
    border-radius: 0px 0px 10px 10px;
    margin-top: -7px;
}

.help-centre-icon-section{
    padding: 8px;
    background-color: #02214A;
    border-radius: 10px;
    width: 50px;
    height: 50px;
}

.help-centre-first-list{
    padding: 20px 32px;
    border-radius: 20px;
    background-color: #001A3D;
    /* margin: auto; */
}

.list-link{
    color: #fff !important;
}

.list-link:hover{
    color: #FFC159 !important;
}

.help-centre-sub-list-container{
    padding-top: 30px;
    background-color: #02214A;
    padding-bottom: 38px;
    border-radius: 10px;
    margin-bottom: 20px;
}

.help-centre-breadcrumbs{
    margin:24px 24px 24px 22px;
}

.help-centre-side-list .list-group-item {
    background: unset;
    border: unset;
}

.help-centre-side-list .list-group-item-action{ 
    display: block !important;
    margin: 15px 0 8px 0;
    padding: 0 8px;
}

.help-centre-side-list .list-group-item-action.active{ 
    border-left: 3px solid #FFC159;
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px;
}

.help-centre-side-list .list-group-item{
    color: #fff;
    cursor: pointer;
}

.help-centre-side-list .list-group-item.active{ 
    color: #FFC159;
}

.help-centre-side-list.list-group > .list-group > .list-group-item {
    padding-left: 24px;
}

.list-group-item:hover{
    color: #FFC159 !important;
}

.help-centre-other-ways-container{
    margin: 30px 0 32px 0;
}

.help-centre-other-ways-title {
    margin-bottom: 10px;
}

.help-centre-other-ways-list {
    padding: 32px 80px;
    border-radius: 20px;
    background-color: #02214A;
    height: 100%;
}

.help-centre-other-ways-list-container {
    padding-bottom: 60px;
}

.help-centre-other-ways-list:hover {
    background: #062B5E;
    cursor: pointer;
}

.help-centre-sub-sub-list-container {
  background-color: #02214A;
    border-radius: 8px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 20px;
}

.help-centre-sub-sub-list-section {
    background-color: #001A3D;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.sub-sub-list-template-box {
    background-color: #001A3D;
    padding: 20px 25px;
    border-radius: 8px;
    height: 100%;
}

.sub-sub-list-template-box .table th,
.sub-sub-list-template-box .table td {
    color: #FFFFFF;
    border: 1px solid #d6dadd;
}

.list-detail-main-title {
    padding-bottom: 15px;
}

.list-detail-title {
    padding-bottom: 20px;
}

.list-detail {
    padding-bottom: 15px;
}

.help-centre-sub-list-wrapper {
    flex-wrap: wrap;
    flex: 50% 1;
    height: fit-content;
}

.help-centre-sub-list-flexbox {
    width: 45%;
}
.sub-category-faq-titles:hover .first{
    color: #FFC159 !important;
}
/*///////media///*/

@media (max-width: 1199px) {
    .vip-event-company-summary-title{
        margin: 18px 0 20px 30px;
    }
}

@media (max-width: 767px) {
    .vip-event-box {
        background-color: none;
        width: 200%;
    }

    .vip-event-scroll {
        background-color: #001A3D;
        border-radius: 8px;
    }

    .current-summary-template-box{
      width: 150%;
    }

    .yes-no-button{
        width: 130px;
    }

    .shareholder-btn{
        min-width: 130px;
        max-width: 130px;
    }

    .shareholder-save-btn{
        width: 130px;
    }


    /* help-centre */
    .help-centre-title {
        font-size: 16px;
        margin-bottom: 2px;
    }

    .help-centre-search {
        display: flex;
        min-width: 30px;
        min-height: 30px;
    }

    .help-centre-search-btn{
        padding: 3px 4px 3px 8px !important;
    }

    .help-centre-search-wrap{
        top: 60%;
    }

    .help-centre-first-list{
        width: 300px;
    }
}

/*///end media///*/