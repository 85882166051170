/* ////john csss// */
/*Fonts//*/
@font-face {
    font-family: "averta-black";
    src: url(../fonts/AvertaStd-Black.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-bold";
    src: url(../fonts/AvertaStd-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-bold";
    src: url(../fonts/AvertaStd-Bold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-semibold";
    src: url(../fonts/AvertaStd-Semibold.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-thin";
    src: url(../fonts/AvertaStd-Thin.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-semibold-italic";
    src: url(../fonts/AvertaStd-SemiboldItalic.ttf);
    font-display: swap;
}

@font-face {
    font-family: "averta-thin-italic";
    src: url(../fonts/AvertaStd-ThinItalic.ttf);
    font-display: swap;
}

@font-face {
    font-family: "segoe";
    src: url(../fonts/SEGOEUI.TTF);
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(/static/media/Montserrat-Regular.07689d4e.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(https://cdn.i8global.com/i8app/um/static/media/Montserrat-Bold.733964aa.ttf) format('truetype');
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/Montserrat-Regular.ttf) format("truetype");
    font-display: swap;
}

@font-face {
    font-family: Montserrat;
    src: url(../../assets/fonts/Montserrat-Bold.ttf) format("truetype");
    font-weight: bold;
    font-display: swap;
}


@font-face {
    font-family: MuseoModerno-Bold;
    src: url(../../assets/fonts/MuseoModerno-Bold.ttf) format("truetype");
    font-weight: bold;
    font-display: swap;
}
/*///end fonts//*/

/*/////header///*/
.nav.nav-tabs + .tab-content {
    padding: 0;
    background: unset;
    margin-bottom: 0;
    border-radius: 0;
}

.w-100 {
    width: 100%;
}

.slick-slide img {
    display: inline-block;
}

.sport-game-icon {
    cursor: pointer;
}

    .sport-game-icon img {
        max-height: 360px;
    }

.content {
    margin-left: 0px;
}

a:not([href]):hover {
    color: #fff;
}

.hidden {
    display: none;
}

body {
    /*font-family: 'Lato', sans-serif; */
    font-family: "averta-thin";
    background: #001a3d;
    /* color: #fff; */
    font-size: 15px;
    position: relative;
}

html {
    min-width: 768px;
}

.img-responsive {
    display: inline-block;
    max-width: 100%;
}

    .img-responsive .active {
        color: #ffc159;
    }

.img-payment {
    display: inline-block;
    max-width: 120px;
}

.img-payment-fatty-pay {
    display: inline-block;
    max-width: 150px;
    margin-left: 10px;
    margin-right: 10px;
}

.list-inline li {
    display: inline-block;
}

.img-100 {
    display: inline-block;
    width: 100%;
}

.img-one {
    display: inline-block;
    /* width: 100%; */
    width: 400px;
    height: auto;
}

#header-wrapper {
    background-position: top center;
    position: fixed;
    width: 100%;
    height: 180px;
    background-size: 100% 100%;
    backdrop-filter: blur(5px);
    z-index: 2;
}

#header {
    /* background: #001a3d42; */
    /*background: #001a3d42 url(../img/Web_Chritmas.png) no-repeat;*/
    /* background-position: center; */
    background-position: top;
    /* background-size: 100% 100%; */
    background-size: 100%;
    padding-top: 35px;
    padding-bottom: 25px;
    position: absolute;
    width: 100%;
    top: 0px;
    /* backdrop-filter: blur(5px); */
    z-index: 1;
}

    #header .logo {
        width: 125px;
    }


#dropdown-visibile\ header {
    /* background: #001a3d42; */
    /*background: #001a3d42 url(../img/Web_Chritmas.png) no-repeat;*/
    /* background-position: center; */
    background-position: top;
    /* background-size: 100% 100%; */
    background-size: 100%;
    padding-top: 35px;
    padding-bottom: 25px;
    position: absolute;
    width: 100%;
    top: 0px;
    backdrop-filter: blur(5px);
    z-index: 1;
}

    #dropdown-visibile\ header.without-banner {
        position: relative;
        z-index: 2;
        transition: background-color 2s linear;
    }

    #dropdown-visibile\ header.fixedme {
        position: fixed;
        width: 100%;
        /* background: #001a3d; */
        /*background: #001a3d url(../img/Web_Chritmas.png) no-repeat;*/
        /* background-position: center; */
        background-position: top;
        background-size: 100%;
        /* z-index: 9999; */
        z-index: 1000;
    }

#header-wrapper.without-banner {
    position: relative;
    z-index: 2;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

    #header-wrapper.without-banner.fixedme {
        position: fixed;
        z-index: 1001;
        animation: slideDown 0.35s ease-out;
    }

@keyframes slideDown {
    from {
        transform: translateY(-100%);
    }

    to {
        transform: translateY(0);
    }
}

#header.fixedme {
    position: fixed;
    width: 100%;
    /* background: #001a3d; */
    /*background: #001a3d url(../img/Web_Chritmas.png) no-repeat;*/
    /* background-position: center; */
    background-position: top;
    /* background-size: 100% 100%; */
    background-size: 100%;
    /* z-index: 9999; */
    z-index: 1000;
}

.navbar {
    background-color: transparent !important;
    display: block;
}

.main-nav {
    display: block;
    width: 100%;
    text-align: right;
    padding: 0px;
    /*padding-top: 21px; */
}

    .main-nav li {
        display: inline-block;
        margin-right: 25px;
        vertical-align: middle;
    }

.header-user-name {
    display: flex;
}

.main-nav li:last-child {
    margin-right: 0px;
}

.main-nav li a {
    color: #ffffff;
    font-weight: bold;
    padding: 0px;
    font-size: 15px;
    font-family: "averta-bold";
    cursor: pointer;
}

a.nav-link.active {
    color: #ffc159;
}

.hsearch {
    display: flex;
}

.hsearch-input {
    height: 32px;
    border: 1px solid #ffffff33;
    border-bottom-left-radius: 50px;
    border-top-left-radius: 50px;
    font-weight: 300;
    padding-left: 10px;
    border-right: 0px;
    width: 81%;
    font-size: 14px;
    padding-right: 5px;
    background: #ffffff33;
    font-family: "averta-semibold";
    color: #fff;
}

select:focus-visible {
    outline: unset;
}

input:focus-visible {
    outline: unset;
}

.hsearch-btn {
    border: 1px solid #ffffff33;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    height: 32px;
    display: inline-block;
    padding: 2px 10px 0px 5px !important;
    vertical-align: middle;
    border-left: 0px;
    width: 19%;
    background: #ffffff33;
}

.clicked #hsearch-input {
    visibility: hidden;
}

.clicked .hsearch-btn {
    background-color: transparent;
    border-color: transparent;
}

.sign-register-box {
    display: flex;
    gap: 25px;
}

.main-nav .sign-register-box a {
    font-size: 13px;
    display: block;
    padding: 5px 0px;
    border-radius: 128px;
    width: 99px;
    text-align: center;
}

.head-box {
    display: flex;
    align-items: center;
}

.menu {
    width: 100%;
}

.sign-in-btn a {
    background: linear-gradient( 180deg, rgba(252, 235, 55, 1) 0%, rgba(253, 183, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
}

.register-btn a {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 82, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
}

.date-lang-box {
    display: flex;
    color: #fff;
    font-family: "averta-bold";
    font-size: 15px;
    align-items: center;
    justify-content: flex-end;
}

    .date-lang-box select {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        padding-right: 13px;
        color: white;
        background: transparent url(../img/assets/arrow-down.png) no-repeat 96% center;
        background-size: 13px;
        border: 0px !important;
        cursor: pointer;
    }

    .date-lang-box > div:first-child:after {
        content: " ";
        display: inline-block;
        background: #ffc159;
        width: 3px;
        height: 12px;
        margin: 0px 0px 0px 14px;
    }

    .date-lang-box .dropdown-menu {
        min-width: 3rem;
        background-color: #ffffff;
        /* background-color: #dbdbdb; */
        z-index: 9999;
    }

.drop-shortcut {
    width: 13px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}
/*.login .sign-register-box{
	  display: none;
  }*/
.flex-login {
    display: none;
}

.login .flex-login {
    display: flex;
    align-items: center;
}

.header-account {
    color: #fff;
    font-family: "averta-bold";
    font-size: 15px;
    border: 2px solid #ffc159;
    padding: 4px 20px;
    border-radius: 50px;
    display: flex;
}

.select-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding-right: 20px;
    color: #fff;
    background: transparent url(../img/assets/arrow-down.png) no-repeat 100% center;
    background-size: 12px;
    border: 0px !important;
}

.header-currency {
    padding: 0px 30px 0px 0px;
    /*border-left: 2px solid #FFC159;*/
    /*margin-left: 10px;*/
    position: relative;
    line-height: 1;
}

.profile-picture {
    position: absolute;
    right: -23px;
    top: -5px;
    cursor: pointer;
}

.login-dropdown-btn {
    width: 13px;
    margin-left: 10px;
    margin-top: -2px;
    cursor: pointer;
}

.settings-dropdown ul {
    display: flex;
    justify-content: flex-end;
    column-gap: 30px;
    margin-bottom: 0px;
}

.settings-dropdown a {
    color: #fff;
    font-family: "averta-bold";
    font-size: 12px;
    display: block;
    text-align: center;
}

.settings-dropdown ul .nav-img {
    height: 25px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.settings-dropdown p {
    margin-bottom: 0px;
}

.settings-dropdown .logout-btn {
    color: #FFFFFF;
    border-left: 2px solid #FFFFFF;
    padding-left: 30px;
    cursor: pointer;
}

    .settings-dropdown .logout-btn .nav-img {
        position: relative;
        display: inline-block;
    }

    .settings-dropdown .logout-btn:hover {
        color: #ffc159;
        border-left: 2px solid #ffc159;
    }

    .settings-dropdown .logout-btn .logout-color {
        width: 20px;
        height: 20px;
        display: none;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 99;
        right: 0;
        margin: auto;
    }

    .settings-dropdown .logout-btn:hover .logout-color {
        display: inline-block;
    }

.settings-dropdown .logout-white {
    /* display: none; */
    width: 20px;
    height: 20px;
    /* position: absolute;
    top: 2px;
    left: 0;
    z-index: 99;
    right: 0;
    margin: auto; */
}

.settings-dropdown {
    display: none;
}

.parent {
    position: relative;
}

    .parent a span {
        font: normal normal normal calc(26px * var(--text-multiplier, 0.7)) FontAwesome;
        line-height: 0.85;
        margin-left: 10px;
    }

    .parent .child {
        position: absolute;
        background-color: #001a3d;
        border-radius: 5px;
        width: 155px;
        text-align: center;
        right: 0px;
        margin-top: 13px;
        display: none;
        z-index: 2;
    }

        .parent .child:before {
            background-color: #ffc159;
            content: " ";
            display: block;
            width: 70px;
            height: 4px;
            position: absolute;
            top: -4px;
            right: 10px;
            border-radius: 20px 20px 0px 0px;
        }

        .parent .child li {
            display: block;
            margin-right: 0px;
        }

        .parent .child a {
            display: flex;
            width: 100%;
            font-family: "averta-semibold";
            padding: 10px 20px 10px 0;
            align-items: center;
            justify-content: space-between;
        }

            .parent .child a .first {
                width: 50px;
                text-align: center;
            }

        .parent .child li:hover {
            background-color: #ffffff3b;
        }

        .parent .child li:first-child:hover {
            border-top-right-radius: 10px;
            border-top-left-radius: 10px;
        }

        .parent .child li:last-child:hover {
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
        }

.icon-up-down-arrow {
    font-size: 12px;
}

.date-lang-box .dropdown button {
    -webkit-appearance: none;
    appearance: none;
    padding: 0px;
    padding-right: 24px;
    color: white;
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAABHNCSVQICAgIfAhkiAAAAG9JREFUOE+t04ERgCAIBVCYrDXcJtumNZzMrLw4D0GrzwDvPhyfCTwM9ggP5hQiEa+YpHm7EmLQgi17fFb+h97YGa654TdUMAW+X7/FuuA8qjETHKN9zAVt1MaGoEZ9bAoUtLxEfQ2vBPjqYSonygFqcDioLgCfmgAAAABJRU5ErkJggg==) no-repeat 96% center;
    background-size: 13px;
    border: 0px !important;
    cursor: pointer;
    font-family: "averta-bold";
    font-size: 15px;
    text-transform: uppercase;
    padding: 0px 32px 0px 14px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

    .date-lang-box .dropdown button:after {
        display: none;
    }

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
    -webkit-appearance: none;
    appearance: none;
    color: white;
    background: transparent url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAABHNCSVQICAgIfAhkiAAAAG9JREFUOE+t04ERgCAIBVCYrDXcJtumNZzMrLw4D0GrzwDvPhyfCTwM9ggP5hQiEa+YpHm7EmLQgi17fFb+h97YGa654TdUMAW+X7/FuuA8qjETHKN9zAVt1MaGoEZ9bAoUtLxEfQ2vBPjqYSonygFqcDioLgCfmgAAAABJRU5ErkJggg==) no-repeat 96% center;
    background-size: 13px;
    border: 0px !important;
    cursor: pointer;
    font-family: "averta-bold";
    font-size: 15px;
    text-transform: uppercase;
    padding: 0px 32px 0px 14px;
}

.date-lang-box .dropdown-item {
    padding: 5px 10px;
}

.refresh-wallet-btn:hover .tooltip-text {
    visibility: visible !important;
}
/*///end header///*/

/*///////home////////*/
.banner-background {
    /*height: 300px;*/
    background-size: 100% !important;
    position: relative;
    margin: 0px;
    color: #fff;
}

.slide-buttons button {
    transform: skew(-42deg);
    border-radius: 0px;
    background: #cccccc;
    /* color: #b5bdc7; */
    height: 4px;
    width: 50px;
    margin: 0px 5px;
    padding: 0px;
    color: transparent;
    border: 0px;
}

    .slide-buttons button.active {
        background: #fff;
    }

.category-section {
    background: #02235082;
}

.category-box {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: visible;
}

    .category-box li a {
        font-size: 13px;
        color: #fff;
        font-family: "averta-bold";
        padding: 10px 0px;
        /* min-height: 47px; */
        min-height: 60px;
    }

        .category-box li a:hover {
            background: linear-gradient( 90deg, rgba(17, 59, 116, 1) 0%, rgba(9, 48, 101, 1) 56%, rgba(2, 37, 86, 1) 100% );
            border-radius: 15px 15px 0px 0px;
        }

        .category-box li a.active {
            background: linear-gradient( 90deg, rgba(17, 59, 116, 1) 0%, rgba(9, 48, 101, 1) 56%, rgba(2, 37, 86, 1) 100% );
            border-radius: 15px 15px 0px 0px;
        }

    .category-box li {
        width: 12.5%;
        width: 135px;
    }

.cat-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

    .cat-item .cat-img {
        margin-right: 10px;
    }

h2 {
    font-family: "Roboto", sans-serif;
    color: #fff;
    font-size: 31px;
    font-weight: bold;
}

.section-padding {
    padding: 3em 0px;
}

.provider-slider .slick-prev:before {
    content: " ";
    background: url(../img/prev.png) no-repeat center;
    box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -moz-box-shadow: 0px 0px 30px -3px rgba(225, 176, 48, 0.75);
    border: 1px solid #e1b030;
    width: 33px;
    height: 34px;
    display: block;
    border-radius: 30px;
    position: absolute;
    right: 0;
    top: 0;
}

.provider-slider .slick-next:before {
    content: " ";
    background: url(../img/next.png) no-repeat center;
    box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -moz-box-shadow: 0px 0px 30px -3px rgba(225, 176, 48, 0.75);
    border: 1px solid #e1b030;
    width: 33px;
    height: 34px;
    display: block;
    border-radius: 30px;
}

.provider-slider .slick-arrow {
    position: absolute;
}

.provider-slider .slick-next {
    z-index: 2;
    right: -30px;
}

.provider-slider .slick-prev {
    z-index: 2;
    left: -30px;
}

.provider-item {
    text-align: center;
    cursor: pointer;
}

.title-with-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .title-with-filter h2 {
        display: flex;
        align-items: center;
    }

        .title-with-filter h2 .mr-2 {
            margin-right: 15px !important;
        }

div.dropdown1 {
    position: relative;
}

.test {
    z-index: 99999;
}

.filter-section {
    display: flex;
    background: #02214a;
    border-radius: 13px;
    cursor: pointer;
    padding: 8px 20px 8px 20px;
    min-width: 180px;
    justify-content: space-between;
}

.dropdown-menu.show {
    inset: -20px auto auto -18px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translate3d(95px, 49px, 0px) !important;
}

.dropdownbtn {
    /* background: #02214a url(../img/selectdown.png) no-repeat 90% center; */
    color: #fff;
    /* padding: 8px 20px 8px 20px; */
    /* min-width: 180px; */
    border-radius: 13px;
    cursor: pointer;
    font-size: 15px;
}

.arrow-dropdownbtn {
    color: #fff;
    background: #02214a url(../img/selectdown.png) no-repeat 90% center;
    width: 14px;
    padding-right: 20px;
    height: 24px;
}

div.dropdown1 > div.list {
    display: none;
    position: absolute;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
    background-color: #ffffff;
    border-color: #ffffff;
    color: #0067b1;
    font-family: "lato-bold";
    font-size: calc(18px * var(--text-multiplier, 0.7));
    width: 100%;
    margin: 0.125rem 0 0;
    border-radius: calc(0.5rem * var(--text-multiplier, 0.85));
    padding: calc(15px * var(--pad-multiplier, 0.7)) calc(0px * var(--pad-multiplier, 0.7));
}

    div.dropdown1 > div.list > div.item {
        padding: calc(0px * var(--pad-multiplier, 0.7)) calc(30px * var(--pad-multiplier, 0.7));
        cursor: pointer;
    }

div.dropdown1.open > div.list {
    display: block;
    z-index: 1;
    background-color: #02214a;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-size: 13px;
    color: #fff;
    line-height: 13px;
    height: 265px;
    padding: 24px 8px 0px 20px;
    /* z-index: 999; */
    z-index: 2;
}

.scrollable-menu {
    height: auto;
    max-height: 185px;
    overflow-x: hidden;
}

.apply-button {
    position: absolute;
    bottom: 9px;
    /* bottom: 5px; */
    /* margin: 10px; */
}

.filter-provider-name {
    padding-right: 10px;
    flex: 50%;
    color: #fff;
    line-height: 20px;
}

.form-checkbox input {
    display: none;
}

.form-checkbox label i {
    background-color: #fff;
    width: 14px;
    height: 15px;
    display: inline-block;
    margin-right: 15px;
    border-radius: 5px;
}

.form-checkbox input:checked + label i {
    background: linear-gradient( 180deg, rgba(152, 255, 203, 1) 0%, rgba(76, 252, 139, 1) 56%, rgba(0, 250, 75, 1) 100% );
}

.form-checkbox label {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: center;
}

.filters {
    display: flex;
    align-items: center;
}

h6 {
    margin: 0px;
    font-size: 15px;
    font-family: "averta-semibold";
}

.game-box {
    position: relative;
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
}

    .game-box .tag {
        position: absolute;
        top: -10px;
        left: -5px;
        /* z-index: 998; */
        z-index: 1;
    }

    .game-box h6 {
        margin-top: 10px;
        text-align: center;
        margin-bottom: 30px;
    }

.banner-slider {
    position: relative;
}

.slick-dotted.slick-slider {
    margin-bottom: 0px;
}

.slick-dots {
    bottom: 10px;
    position: absolute;
    z-index: 1;
}

    .slick-dots button {
        display: none;
    }

    .slick-dots li {
        width: 13px;
        height: 13px;
        border-radius: 50px;
        border: 1px solid #707070;
        background-color: #9caaaf;
        display: inline-block;
        margin: 0px 5px;
    }

        .slick-dots li button {
            opacity: 0;
        }

            .slick-dots li button:before {
                display: none;
            }

        .slick-dots li.slick-active {
            background-color: #ffc159;
        }

.see-all-btn {
    background: #02214a;
    color: #fff;
    padding: 8px 20px 8px 20px;
    /* min-width: 180px; */
    border-radius: 13px;
    display: block;
    font-size: 15px;
    /*line-height: 1.6;*/
    cursor: pointer;
}

    .see-all-btn:hover {
        color: #fff;
    }

.load-more-btn {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #002e6c;
    background: linear-gradient( 90deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
    padding: 8px 35px;
    border-radius: 50px;
}

h4 {
    font-family: "averta-semibold";
    font-size: 17px;
    color: #fff;
    margin-bottom: 0px;
}

h3 {
    font-family: "averta-semibold";
    font-size: 22px;
    color: #fff;
    margin-bottom: 0px;
}

.btn {
    font-family: "averta-bold";
    font-weight: 600;
    color: #002e6c;
    padding: 8px 35px;
    border-radius: 50px;
    border: 0px;
}

    .btn:hover {
        opacity: 0.8;
    }

    .btn.disabled:hover {
        opacity: 0.65;
        cursor: not-allowed;
    }

.seven-cols .col {
    flex-basis: unset;
    flex-grow: unset;
    width: 14.285714285714285714285714285714% !important;
    *width: 14.285714285714285714285714285714% !important;
}

.register-popup-modal .modal-dialog {
    max-width: unset;
    width: 60%;
    min-width: 500px;
}

.login-popup-modal .modal-dialog {
    max-width: unset;
    /* width: 60%; */
    width: 100%;
}

.register-popup-modal-body-wrapper {
    background-color: #001a3d;
    max-width: 500px;
    margin: auto;
    border-radius: 30px;
}

    .register-popup-modal-body-wrapper .modal-body {
        padding: 0;
    }

.login-popup-modal-body-wrapper {
    background-color: #001a3d;
    border-radius: 30px;
}

    .login-popup-modal-body-wrapper .modal-body {
        padding: 0;
    }

.transaction-detail-popup-modal-body-wrapper {
    background-color: #ffffff;
    border-radius: 50px;
    /* width: auto; */
}

.transaction-detail-title {
    color: #130c26;
    font-family: "averta-bold";
    font-size: 24px;
    margin-top: 16px;
}

.transaction-detail-amount {
    color: #130c26;
    font-family: "averta-bold";
    font-size: 25px;
    margin-top: 18px;
}

.transaction-detail-date {
    color: #130c26;
    font-family: "averta-semibold";
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
    margin-bottom: 16px;
}

.transaction-detail-status-icon {
    color: #ff0000;
    font-family: "averta-bold";
    font-size: 18px;
    line-height: 22px;
    margin-top: 26px;
}

.transaction-detail-status {
    color: #ff0000;
    font-family: "averta-bold";
    font-size: 18px;
    line-height: 22px;
    margin-top: 8px;
}

.login-popup-modal .modal-dialog {
    /* max-width: 480px; */
    max-width: 882px;
    max-height: 545px;
    /* height: auto; */
}

.register-popup-wrapper {
    display: flex;
    flex-direction: column;
    padding: 35px;
}

.register-popup-modal .custom-input-style-1 {
    border-radius: 100px;
    height: 40px;
}

.referral-code-tips {
    color: white;
    font-weight: bold;
    margin-left: 10px;
}

.login-popup-wrapper {
    display: flex;
}

.register-popup-left {
    width: 50%;
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.register-page-left {
    height: 500px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.login-popup-left {
    width: 50%;
    height: 100%;
    padding: 15px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: rgba(0,26,61,.75);
}

.login-popup-image {
    height: 546px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
}

.register-popup-right {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 15px 30px;
    position: relative;
}

.register-page-right {
    display: flex;
    flex-direction: column;
    padding: 15px 30px;
    position: relative;
}

.login-popup-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    padding: 30px;
    padding-bottom: 75px;
}

.register-first-name {
    width: 100%;
}

.register-last-name {
    width: 100%;
}

.register-phone-number {
    display: flex;
    position: relative;
}

    .register-phone-number .phone-number {
        width: 100%;
    }

.register-checkbox-btn-wrapper {
    display: flex;
    flex-direction: column;
}

.custom-input-style-1 {
    padding: 0px 20px 0px 20px;
    font-size: 11px;
    border-radius: 8px;
    background: #02214a;
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    height: 45px;
}

    .custom-input-style-1:read-only:hover {
        cursor: not-allowed;
    }

    .custom-input-style-1::-webkit-input-placeholder {
        color: #ffffff;
        /* font-style: italic;
  font-size: 13px;
  letter-spacing: 1px; */
    }

.custom-input-style-2 {
    padding: 0px 20px 0px 20px;
    font-size: 11px;
    border-radius: 8px !important;
    background: #02214a;
    border: 1px solid #fff !important;
    color: #fff;
    width: 100%;
    /* width: 435px !important; */
    height: 45px;
}

.birthdate-datepicker .react-datepicker-wrapper {
    width: 100%;
}

.custom-input-style-2:read-only:hover {
    cursor: not-allowed;
}

.custom-input-style-2::-webkit-input-placeholder {
    color: #ffffff;
    /* font-style: italic;
  font-size: 13px;
  letter-spacing: 1px; */
}

.form-white-input::-webkit-input-placeholder {
    color: #ffffff;
}

.custom-input-style-1.rounded {
    border-radius: 25px !important;
}

.phone-number-prefix {
    width: 77px;
    padding: 10px;
    margin-right: 10px;
}

.login-username-label {
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.login-password-label {
    color: white;
    font-size: 15px;
    font-weight: bold;
}

.register-checkbox {
    display: flex;
    align-items: center;
}

    .register-checkbox input {
        margin-right: 5px;
    }

.login-keep-logged-in {
    margin-bottom: 15px;
}

.login-remember-password {
    margin-bottom: 15px;
}

.login-checkbox-btn-wrapper .checkbox-wrapper {
    display: flex;
    align-items: center;
}

    .login-checkbox-btn-wrapper .checkbox-wrapper span {
        color: white;
        font-weight: bold;
        letter-spacing: 0.8px;
        font-size: 11px;
    }

    .login-checkbox-btn-wrapper .checkbox-wrapper input {
        margin-right: 5px;
    }

.invalid-feedback {
    font-weight: bold;
    font-family: "averta-bold";
}

.register-popup-right .invalid-feedback {
    margin-top: -10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 90%;
}

.register-page-right .invalid-feedback {
    margin-top: -10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 90%;
}

.login-popup-right .invalid-feedback {
    margin-top: -10px;
    margin-left: 10px;
    font-weight: bold;
    font-size: 90%;
}

.forgot_password_txt_btn {
    color: #ffc159;
    font-family: "averta-bold";
    font-size: 10px
}

    .forgot_password_txt_btn:hover {
        color: white;
        cursor: pointer;
    }

.forgot_password_div {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    padding-top: 12px;
    padding-bottom: 12px;
}

.forgot_password_header {
    color: white;
    font-family: "averta-semibold";
    font-size: 13px;
    margin-bottom: 12px;
    padding-bottom: 12px;
}

.buttonIn {
    position: relative;
}

.input {
    width: 100%;
}

.button {
    position: absolute;
    top: 34px;
    right: 14px;
    z-index: 2;
    cursor: pointer;
    color: #ffc159;
    transform: translateX(2px);
    font-family: "averta-semibold";
    font-size: 11px;
}

.custom-btn-style-1 {
    display: block;
    text-align: center;
    font-family: "averta-bold";
    font-weight: 600;
    color: #002e6c;
    padding: 8px 35px;
    border-radius: 50px;
    border: 0px;
    vertical-align: middle;
    font-size: 0.75rem;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    -webkit-appearance: button;
    margin-top: 10px;
    width: 100%;
    max-width: 100%;
    background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 12px;
}

button.custom-btn-style-1:hover {
    color: white;
}

button.custom-btn-style-1:disabled {
    opacity: 0.5;
}

    button.custom-btn-style-1:disabled:hover {
        color: #d09327;
        cursor: not-allowed;
    }

#downline-group-modern button.custom-btn-style-1 {
    padding-top: 13px;
    padding-bottom: 12px;
}

.register-phone-number .send-tac-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    height: 25px;
    width: fit-content;
    font-size: 12px;
    font-weight: bold;
    color: rgba(255, 193, 89, 1);
    background: transparent;
    text-decoration: underline;
    border: 0;
    border-radius: 10px;
}

.send-tac-btn:disabled {
    opacity: 0.8;
}

.spacing-small {
    letter-spacing: -0.6px;
}

.register-submit-btn {
    position: absolute;
    bottom: 15px;
    left: 7%;
    width: 85% !important;
}

.register-tac-wrapper .tac-countdown {
    margin-left: 10px;
    /* margin-top: -5px; */
    margin-top: 5px;
}


.referral-code-error {
    margin-top: -10px;
    margin-left: 10px;
    font-weight: bold;
}

.tnc-container {
    position: absolute;
    text-align: center;
    padding: 25px 25px 75px 25px;
    overflow-y: scroll;
    height: calc(100% - 56px);
    color: #002C68;
    font-weight: bold;
}

    .tnc-container::-webkit-scrollbar {
        background-color: #efefef;
        width: 8px;
    }

    .tnc-container::-webkit-scrollbar-thumb {
        background-color: #002d6a;
    }

.tnc-logo {
    width: 100px;
    background-color: #002d6a;
    padding: 18px;
    border-radius: 10px;
}

.tnc-top-text {
    font-size: 20px;
    margin-top: 15px;
    font-weight: bold;
}

.tnc-content {
    text-align: justify;
    margin-top: 15px;
}

.tnc-label {
    border-bottom: 1px solid;
    margin-top: 20px;
    color: #002C68;
}

.tnc-list {
    display: flex;
}

.tnc-bullets-wrapper {
    margin-left: 50px;
}

.tnc-bullets {
    display: list-item;
}

.tnc-agree-label {
    display: inline-flex;
    color: white !important;
    padding-left: 0 !important;
    margin-top: 5px;
    white-space: break-spaces;
}


label {
    color: #212121;
    font-weight: 600;
    display: inline-block;
    margin-bottom: 0.5rem;
    font-size: 15px;
}

.tnc-popup-wrapper {
    height: 100vh;
    width: 100vw;
    background: black;
    opacity: 0.5;
    position: fixed;
    left: 0;
    top: 0;
}

.tnc-popup .tnc-popup-header {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    color: #002C68;
    margin-bottom: 15px;
}

.tnc-popup {
    position: fixed;
    align-items: center;
    width: 75vw;
    height: 75vh;
    background-color: white;
    padding: 20px 7px 20px 15px;
    left: 12vw;
    top: 15vh;
    border-radius: 20px;
    z-index: 2;
    display: grid;
}

    .tnc-popup .tnc-container {
        overflow-y: scroll;
        overflow-x: clip;
        height: 50vh;
        margin-bottom: 10px;
        text-align: justify;
        font-size: 16px;
        /* font-size: 12px; */
        color: #002C68;
        letter-spacing: 0.2px;
        padding: 0;
        padding-right: 8px;
        position: relative;
        font-weight: normal;
    }

        .tnc-popup .tnc-container::-webkit-scrollbar {
            background-color: #efefef;
            width: 8px;
        }

        .tnc-popup .tnc-container::-webkit-scrollbar-thumb {
            background-color: #002d6a;
        }

        .tnc-popup .tnc-container .tnc-content {
            margin-top: 0;
        }

        .tnc-popup .tnc-container .tnc-top-text {
            color: #FFC159;
            margin-top: 20px;
            margin-bottom: 0.5rem;
            border-bottom: none;
            font-size: 15px !important;
        }

        .tnc-popup .tnc-container .tnc-label {
            color: #FFC159;
            margin-top: 20px;
            border-bottom: none;
        }

.sidemenu-img {
    width: 20px;
    opacity: 0.5;
}

.active .first .sidemenu-img {
    opacity: 1;
}

.news-detail {
    color: white;
    font-weight: bold;
}

.news-date {
    font-size: 12px;
}

.news-content {
    font-size: 16px;
}

.notification-box {
    font-weight: bold;
}

.register-page-header {
    color: white;
    margin-bottom: 20px;
}

#register {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* width: 100%;
	height: 100%; */
}

    #register .invalid-feedback {
        height: 10px;
        margin-top: 0px;
        font-size: 12px;
        margin-bottom: 10px;
    }

    #register .custom-input-style-1 {
        background: #001a3d;
        cursor: pointer;
    }

.register-bottom-icon {
    position: absolute;
    width: 50%;
    height: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    bottom: -60px;
    right: 0;
}

#register-section {
    padding-bottom: 10em;
    padding-top: 3em;
    padding-left: 80px;
    padding-right: 80px;
}

.downline-search-box .form-group {
    margin-bottom: 0;
}

    .downline-search-box .form-group .input-group {
        margin-bottom: 0 !important;
    }

.downline-search-input {
    background-color: #001a3d;
    border: none;
    color: #fff;
}

    .downline-search-input:focus {
        background-color: #001a3d;
        box-shadow: none;
    }

#downline-search-box-main .input-group-text {
    background-color: #001a3d;
    border: none;
}

    #downline-search-box-main .input-group-text:focus-visible {
        outline: none;
    }

#delGroup {
    cursor: pointer;
}

.create-group-btn {
    margin-top: 25px;
    padding: 0;
}

.group-row-right {
    display: flex;
    align-items: center;
}

.report-list-icon {
    width: 30px;
}

.hoverable:hover {
    text-decoration: underline;
    cursor: pointer;
}

#game-record label.col-form-label,
#comm-history-section label.col-form-label,
#loyalty-history-section label.col-form-label {
    color: white;
}

.card {
    background-color: #001a3d;
    padding: 10px 20px;
}

table.table.reportTable {
    color: white;
}

#rollover-statement-box {
    margin: 20px 0;
}

.settings-modal .modal-content {
    background-color: #001a3d;
}

.settings-modal .modal-title {
    width: 100%;
}

.settings-modal .modal-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 30px
}

.settings-phone-verified {
    position: absolute;
    right: 10px;
    top: 12px;
}

.settings-modal .invalid-feedback {
    /* height: 10px; */
    margin-top: 0px;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
}


.modal-X-btn {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-right: 20px;
    font-size: 2rem;
    color: white;
    font-weight: bold;
}

form.settings-pin-form {
    padding: 10px;
    padding-top: 20px;
}

.settings-pin-form .change-pin-label {
    color: white;
}

.settings-pin-form input.pincode-input-text {
    border-bottom: 2px solid white !important;
}

.keyboard.keyboard-numpad .hg-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1rem;
    max-width: 250px;
    margin: 0 auto;
}

.keyboard.keyboard-numpad .hg-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 1rem;
    max-width: 250px;
    margin: 0 auto;
}

.keyboard.keyboard-numpad .hg-button {
    background: #fff;
    box-shadow: 0 3pt 6pt #ffc1594d;
    border-radius: 50%;
    margin: 0 auto;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

    .keyboard.keyboard-numpad .hg-button:focus,
    .keyboard.keyboard-numpad .hg-button:active {
        background: linear-gradient(180deg, #ffcf7d, #fe9f00);
        outline: none;
    }

    .keyboard.keyboard-numpad .hg-button[data-skbtn="."] {
        display: none;
    }

    .keyboard.keyboard-numpad .hg-button[data-skbtn="0"] {
        grid-area: 4/2 / span 1/2;
    }

    .keyboard.keyboard-numpad .hg-button[data-skbtn="{bksp}"] {
        grid-area: 4/3 / span 1/3;
    }


.pin-row {
    cursor: pointer;
}

.loading-spinner-wrapper {
    text-align: center;
}

#referral .loading-spinner-wrapper {
    color: white;
}

#referral-code .loading-spinner-wrapper {
    color: white;
}

#delReferral {
    margin-right: 25px;
}

.referral-qr-code {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 125px !important;
    height: 125px !important;
    /* ROSE: Important to have border at least 8px if backgroundColor not white color otherwise QR not able to read */
    border: 8px solid #FFFFFF !important;
}

.qr-bg-box {
    display: inline-block;
    position: relative;
}

.referral-link-wrapper {
    position: relative;
}

    .referral-link-wrapper input {
        border-radius: 50px !important;
    }

.st-inline-share-buttons {
    width: 220px;
    z-index: 0 !important;
}

.st-btn {
    padding: 0px 8px !important;
}

    .st-btn > img {
        top: 8px !important;
    }

.product-rate-row {
    display: flex;
    justify-content: space-between;
}

.company-summary.card {
    padding: 0;
    border: none;
}

.company-summary .table {
    margin-bottom: 0;
}

.company-summary .grandtotal td:nth-child(1) {
    border-bottom-left-radius: 6px;
}

.company-summary .grandtotal td:nth-child(4) {
    border-bottom-right-radius: 6px;
}

.header-wallet-balance {
    cursor: pointer;
}

.tooltip-text {
    visibility: hidden;
    position: absolute;
    width: fit-content;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    z-index: 1;
}

.tooltip-text2 {
    visibility: hidden;
    position: absolute;
    right: 0;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 10px;
    z-index: 1;
}

.deposit-and-claim-box .first .dac-icon:hover .tooltip-text2,
.header-wallet-balance:hover .tooltip-text {
    visibility: visible !important;
}

.hover-underline:hover {
    text-decoration: underline;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-input-style-1.rounded:read-only {
    color: grey;
    cursor: not-allowed;
}

.edit-group-title {
    color: white;
    padding: 10px 0 30px 0;
}

.sweet-alert h2 {
    color: #002e6c;
}

.display-flex {
    display: flex;
}

.right-title-box .second {
    cursor: pointer;
}

#referral .wallet-main-boxes {
    justify-content: normal;
}

.referral-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
}

.shareholder-share-percent::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

#downlineform .submit:disabled {
    color: grey;
    background: lightgrey;
    cursor: not-allowed;
}

.no-data-text {
    color: white;
    text-align: center;
    margin-top: 20px;
}

#referral-edit-section .loading-spinner-wrapper {
    color: white;
    margin-top: 10px;
}

.justify-center {
    justify-content: center;
}

.position-relative {
    position: relative;
}

.referral-code-clipboard {
    cursor: pointer;
    position: absolute;
    right: -35px;
}

.category-banner {
    margin: 15px 0px;
}

#rewards .loading-spinner-wrapper {
    color: white;
    margin: 30px;
}

.sweet-alert-content {
    color: black;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.8px;
}

.sweet-alert-container .sweet-alert {
    border-radius: 50px !important;
}

#vip-event-0 .rdtSwitch {
    color: black;
}

#vip-event-0 .dow {
    color: black;
}

.um-wrapper.disabled {
    pointer-events: none;
}

.desktop .v2-um-tag {
    width: 85%;
    height: 67%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 10px;
    border-radius: 16px;
}

.desktop .v2-um-tag-icon {
    background-image: url(/static/media/dashboardv2_um.ec3690dd.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 90%;
    height: 90%;
    cursor: not-allowed;
    margin: auto;
}

@media screen and (min-width: 1032px ) {
    .desktop .v2-um-tag {
        width: 87%;
        height: 78%;
    }

    /* .desktop .v2-um-tag-icon {
        top: 0;
        left: 0;
        width: 110px;
        height: 110px;
    } */
}

@media screen and (min-width: 1075px ) {
    /* .desktop .v2-um-tag-icon {
        top: 0;
        left: 0;
    } */
}

@media screen and (min-width: 1150px ) {
    .desktop .v2-um-tag {
        width: 88%;
        height: 79%;
    }

    /* .desktop .v2-um-tag-icon {
        top: 0;
        left: 0;
        width: 115px;
        height: 115px;
    } */
}

@media screen and (min-width: 1200px ) {
    .desktop .v2-um-tag {
        width: 89%;
        height: 80%;
    }

    /* .desktop .v2-um-tag-icon {
        top: 0;
        left: 0;
        width: 125px;
        height: 125px;
    } */
}

.desktop-new-games .v2-um-tag {
    width: 85%;
    height: 67%;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 10px;
    border-radius: 16px;
}

.desktop-new-games .v2-um-tag-icon {
    background-image: url(/static/media/dashboardv2_um.ec3690dd.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 9px;
    left: 19px;
    width: 100px;
    height: 100px;
    cursor: not-allowed;
}

@media screen and (min-width: 1032px ) {
    .desktop-new-games .v2-um-tag {
        width: 87%;
    }

    .desktop-new-games .v2-um-tag-icon {
        top: 9px;
        left: 16px;
        width: 110px;
        height: 110px;
    }
}

@media screen and (min-width: 1075px ) {
    .desktop-new-games .v2-um-tag-icon {
        top: 8px;
        left: 20px;
    }
}

@media screen and (min-width: 1150px ) {
    .desktop-new-games .v2-um-tag {
        width: 88%;
    }

    .desktop-new-games .v2-um-tag-icon {
        top: 12px;
        left: 20px;
        width: 115px;
        height: 115px;
    }
}

@media screen and (min-width: 1200px ) {
    .desktop-new-games .v2-um-tag {
        width: 89%;
        height: 72%;
    }

    .desktop-new-games .v2-um-tag-icon {
        top: 14px;
        left: 21px;
        width: 125px;
        height: 125px;
    }
}
/*////////end home///*/

/*////side menu///*/
.side-menu-profile {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .side-menu-profile .first {
        position: relative;
    }

        .side-menu-profile .first span {
            position: absolute;
            bottom: 0px;
            right: 0px;
        }

    .side-menu-profile h4 span {
        font-size: 13px;
    }

.profile-border:after {
    content: " ";
    display: block;
    max-width: 225px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

#side-menu a {
    color: #fff;
    display: flex;
    align-items: center;
    font-family: "averta-semibold";
    font-size: 16px;
    max-width: 210px;
    position: relative;
    padding: 10px 15px;
    border-radius: 7px;
    margin-bottom: 5px;
    cursor: pointer;
}

    #side-menu a:hover {
        background: #02214a;
    }

    #side-menu a.active {
        background: #02214a;
    }

#side-menu .first {
    width: 50px;
    /*text-align: center;*/
}

.side-logout-btn {
    width: 100%;
    display: block;
    text-align: center;
    max-width: 225px;
    background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
}

.btn.side-logout-btn:hover {
    color: white;
    opacity: unset;
    cursor: pointer;
}

.notification-count {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 83, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
    font-size: 11px;
    padding: 4px 3px;
    border-radius: 50px;
    position: absolute;
    right: 0px;
    line-height: 11px;
    top: 12px;
}

.right-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

    .right-title-box .second span {
        display: block;
        font-family: "averta-semibold";
        font-size: 12px;
        color: #6d99d4;
    }

.right-title-hr {
    border-bottom: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 20px;
}
/*////////end home///*/

/*////side menu///*/
.side-menu-profile {
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .side-menu-profile .first {
        position: relative;
    }

        .side-menu-profile .first span {
            position: absolute;
            bottom: 0px;
            right: 0px;
        }

    .side-menu-profile h4 span {
        font-size: 13px;
    }

.profile-border:after {
    content: " ";
    display: block;
    max-width: 225px;
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
}

#side-menu a {
    color: #fff;
    display: flex;
    align-items: center;
    font-family: "averta-semibold";
    font-size: 16px;
    max-width: 210px;
    position: relative;
    padding: 10px 15px;
    border-radius: 7px;
    margin-bottom: 5px;
    cursor: pointer;
}

    #side-menu a:hover {
        background: #02214a;
    }

    #side-menu a.active {
        background: #02214a;
    }

#side-menu .first {
    width: 50px;
    /*text-align: center;*/
}

.side-logout-btn {
    width: 100%;
    display: block;
    text-align: center;
    max-width: 225px;
    background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
}

.notification-count {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 83, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
    font-size: 11px;
    padding: 4px 3px;
    border-radius: 50px;
    position: absolute;
    right: 0px;
    line-height: 11px;
    top: 12px;
}

.right-title-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
}

    .right-title-box .second span {
        display: block;
        font-family: "averta-semibold";
        font-size: 12px;
        color: #6d99d4;
    }

.right-title-hr {
    border-bottom: 1px solid #fff;
    margin-top: 30px;
    margin-bottom: 20px;
}

.deposit-and-claim-box {
    background-color: #02214a;
    padding: 20px 0px;
    border-radius: 8px;
    display: flex;
}

.member-level {
    cursor: unset !important;
}

.deposit-and-claim-box h3 span {
    font-size: 16px;
    margin-right: 5px;
}

.deposit-and-claim-box .first {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.deposit-and-claim-box .second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}


.deposit-and-claim-box .first-claim {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

    .deposit-and-claim-box .first-claim > div {
        width: 100%;
    }

.deposit-and-claim-box .first .img2 {
    display: none;
}

.deposit-and-claim-box .first[aria-expanded="true"] .img1 {
    display: none;
}

.deposit-and-claim-box .first[aria-expanded="true"] .img2 {
    display: inline-block;
}

.deposit-divider {
    /* width: 33%; */
    width: 50%;
    padding: 0px 25px;
    border-right: 1px solid #000;
}

.deposit-and-claim-box .deposit-divider:last-child {
    border: 0px;
}

.small-btn {
    font-family: "averta-semibold";
    font-size: 14px;
    padding: 3px 0px;
    min-width: 100px;
    text-align: center;
    border-radius: 50px;
    display: block;
    cursor: pointer;
}

.green-btn {
    background: linear-gradient( 180deg, rgb(152 255 203) 0%, rgba(71, 252, 135, 1) 56%, rgba(0, 250, 75, 1) 100% );
    color: #001a3d;
    border: 1px solid #98ffcb;
}

.transparent-btn {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    font-family: "averta-thin";
}

.dac-icon {
    position: absolute;
    right: calc(20% - 20px);
    top: calc(50% - 20px);
}

.wallet-main-boxes {
    background-color: #02214a;
    display: flex;
    padding: 0px 25px;
    border-radius: 8px;
    margin-top: 20px;
    justify-content: space-between;
    height: 60px;
    align-items: center;
}

    .wallet-main-boxes .first {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    .wallet-main-boxes h3 {
        font-size: 16px;
    }

    .wallet-main-boxes .first .img {
        width: 50px;
    }

.transaction-btn {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    width: unset;
    padding: 3px 20px;
    font-family: "averta-thin";
}

.right-title-box .first a {
    cursor: pointer;
    margin-right: 20px;
}

.template-box {
    background-color: #02214a;
    padding: 20px 25px;
    border-radius: 8px;
}

.font-20 {
    font-size: 20px;
}

.font-24 {
    font-size: 24px;
}

.font-18 {
    font-size: 18px;
}

.font-16 {
    font-size: 16px;
}

.font-15 {
    font-size: 15px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.font-11 {
    font-size: 11px;
}

#payment-method-box {
    padding: 20px 40px;
}

#my-tabs a {
    font-family: "averta-semibold";
    color: #fff;
    display: block;
    padding-bottom: 10px;
    cursor: pointer;
    /* margin-bottom: 0px; */
}

#my-tabs .active {
    color: #ffc159;
    position: relative;
    background-color: transparent;
}

#my-tabs li {
    margin-right: 40px;
}

#my-tabs {
    border-bottom: 1px solid #000;
}

    #my-tabs .active:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #ffc159;
        bottom: -2px;
        z-index: 1;
        left: 0px;
    }



#deposit-tabs {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

    #deposit-tabs a {
        font-family: "averta-semibold";
        color: #898F97;
        display: block;
        padding-bottom: 10px;
        cursor: pointer;
        /* margin-bottom: 0px; */
    }

    #deposit-tabs .active {
        color: #3369FF;
        position: relative;
        background-color: transparent;
    }

    #deposit-tabs li {
        margin-right: 40px;
    }

    #deposit-tabs .active:after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #3369FF;
        bottom: -2px;
        z-index: 1;
        left: 0px;
    }


.payment-methods-radio {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

    .payment-methods-radio .form-checkbox label {
        margin-bottom: 0px;
        padding: 0px 20px;
        border-radius: 8px;
        border: 1px solid transparent;
        cursor: pointer;
        width: 100px;
        justify-content: center;
    }

    /* Fatty Pay Icon    */
    .payment-methods-radio .form-checkbox:last-child label {
        padding: 0px;
    }

    .payment-methods-radio .form-checkbox label div {
        min-height: 50px;
        display: flex;
        align-items: center;
    }

    .payment-methods-radio .form-checkbox input:checked + label {
        border: 1px solid #fff;
        background: #ffffff26;
    }

    .payment-methods-radio .img2 {
        display: none;
    }

    .payment-methods-radio .form-checkbox input:checked + label .img1 {
        display: none;
    }

    .payment-methods-radio .form-checkbox input:checked + label .img2 {
        display: block;
    }

.form-white-select {
    padding: 0px 20px;
    font-size: 11px;
    border-radius: 8px;
    border: 1px solid #fff;
    color: #fff;
    width: 100%;
    height: 45px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #02214a url(../img/assets/drop.svg) no-repeat 95% center;
    /*background-size: 62px;*/
}

.form-white-input {
    padding: 0px 20px 0px 20px;
    font-size: 11px;
    border-radius: 8px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: #02214a;
    color: #fff;
    width: 100%;
    height: 100%;
    outline: none;
}

.form-divider {
    display: flex;
    column-gap: 30px;
    margin-bottom: 20px;
}

    .form-divider > div {
        width: 100%;
    }

.amount-reminder {
    color: #ff0000;
    margin-left: 15px;
    margin-top: 5px;
    font-weight: bold;
    font-family: 'averta-bold';
    letter-spacing: 0.5px;
}

.depostit-submit-btn {
    width: 100%;
    max-width: 100%;
    background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
    line-height: 1;
    padding-top: 12px;
    padding-bottom: 12px;
}

.cancel-btn {
    width: 100%;
    background: linear-gradient( 180deg, rgb(255 110 144) 0%, rgba(254, 83, 115, 1) 56%, rgba(253, 59, 90, 1) 100% );
    color: #fff;
}

    .cancel-btn:hover {
        color: #fff;
    }

.deposit-qr {
    background: #001a3d;
    padding: 20px 20px;
    border-radius: 20px;
    text-align: center;
}

    .deposit-qr p {
        color: #fff;
        font-family: "averta-bold";
    }

    .deposit-qr a {
        color: #ffc159;
    }

.offline-icon {
    background-position: 50%;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    position: absolute;
}

.offline-tng {
    /* background-color: #727272; */
    opacity: .8;
    position: relative;
}

.option-image {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
}

input[type="date"] {
    position: relative;
}

.circled-checkbox label {
    color: #fff;
    font-family: "averta-semibold";
}

    .circled-checkbox label i {
        width: 15px;
        height: 15px;
        border-radius: 20px;
    }

.withdrawal-cash {
    background-color: #001a3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    height: 45px;
    border-radius: 8px;
}

    .withdrawal-cash input {
        text-align: right;
        font-family: "averta-semibold";
        color: #fff;
        background: transparent;
        border: 0px;
        box-shadow: unset;
        width: 100%;
    }

    .withdrawal-cash > div {
        width: 100%;
    }

.pending-transaction-box {
    display: flex;
    position: relative;
}

.pending-transaction-time {
    font-family: "averta-bold";
    font-size: 35px;
    line-height: 35px;
    color: #fff;
    margin-bottom: 0px;
}

.pending-transaction-box .first {
    position: relative;
    min-width: 200px;
}

    .pending-transaction-box .first:after {
        content: " ";
        width: 1px;
        height: 50px;
        background: #ffc159;
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translate(0, -50%);
    }

.pending-transaction-box .pt-min-title {
    font-family: "averta-semibold";
    color: #ffc159;
}

.pending-transaction-box .second {
    padding-left: 45px;
}

.pending-transaction-box .pt-money {
    font-family: "averta-semibold";
    color: #fff;
    font-size: 30px;
}

    .pending-transaction-box .pt-money span {
        font-size: 20px;
    }

.text-red {
    color: #ff0000;
    font-weight: bold;
}

.text-gold {
    color: #ffc159 !important;
}

.font-semi-italic {
    font-family: "averta-semibold-italic";
}

.font-semi {
    font-family: "averta-semibold";
}

.font-bold {
    font-family: "averta-bold";
}

.font-thin-italic {
    font-family: "averta-thin-italic";
}

.font-thin {
    font-family: "averta-thin";
}

.circle-orange {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 84, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50px;
    position: absolute;
    top: 0px;
    right: 0px;
}

.circle-orange-notification {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 84, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
    width: 20px;
    height: 20px;
    display: block;
    border-radius: 50px;
    position: absolute;
    /* top: 0px; */
    top: calc(50% - 10px);
    right: 0px;
    font-size: 11px;
    text-align: center;
    padding-top: 1px;
}

.circle-green {
    background: linear-gradient( 180deg, rgba(152, 255, 203, 1) 0%, rgba(76, 252, 139, 1) 56%, rgba(0, 250, 75, 1) 100% );
    width: 16px;
    height: 16px;
    display: block;
    border-radius: 50px;
}

.pending-transaction-box .pt-second-box {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0px;
    right: 0px;
}

    .pending-transaction-box .pt-second-box p {
        margin-right: 40px;
    }

.transac-history-search-box {
    background-color: #001a3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    height: 45px;
    border-radius: 8px;
}

    .transac-history-search-box .th-date-search {
        font-family: "averta-semibold";
        color: #fff;
        background: transparent;
        border: 0px;
        box-shadow: unset;
        justify-content: space-between;
        width: 100%;
    }

    .transac-history-search-box .th-search-btn {
        width: 40px;
        text-align: right;
        border-left: 1px solid #fff;
        margin-left: 130px;
        cursor: pointer;
    }

.filter-with-scroll-box {
    display: flex;
    align-items: center;
    overflow-x: auto;
    justify-content: space-between;
    column-gap: 20px;
    padding: 15px 0px;
    /*min-width: 775px;*/
}

    .filter-with-scroll-box .filter-items a {
        display: block;
        font-size: 12px;
        color: #fff;
        border: 1px solid #fff;
        border-radius: 100px;
        /* width: 80px; */
        width: 115px;
        padding: 5px 0px;
        text-align: center;
        text-decoration: unset !important;
        cursor: pointer;
    }

        .filter-with-scroll-box .filter-items a:hover {
            background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
            color: #002e6c;
            border-color: rgb(253 182 46);
            font-family: "averta-bold";
        }

        .filter-with-scroll-box .filter-items a.active {
            background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
            color: #002e6c;
            font-family: "averta-bold";
            border-color: rgb(253 182 46);
        }

.gold-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
}

.gold-scroll::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
}

.gold-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffc159;
}

.white-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: transparent;
}

.white-scroll::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
    width: 5px;
    margin-right: 5px;
}

.white-scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #ffffff;
}

.thr-table {
    width: 100%;
}

.thr-thead {
    width: 100%;
}

.thr-tbody {
    padding-top: 15px;
}

.thr-item {
    text-align: center;
}

.thr-item-date {
    color: #ffffff;
}

.thr-item-amount {
    color: #ffffff;
}

.font-thick {
    color: #ffffff;
}

.transac-history-result {
    display: flex;
    flex: 1;
    border-bottom: solid 1px black;
    padding-top: 15px;
    /*padding-bottom: 12px; */
}

    .transac-history-result > div,
    .transac-history-result > th {
        width: 25%;
        text-align: center;
        color: #fff;
        /* padding-bottom: 15px; */
    }

.transac-history-result-total {
    display: flex;
    flex: 1;
    padding-top: 15px;
    /*padding-bottom: 12px; */
}

    .transac-history-result-total > div,
    .transac-history-result-total > th {
        width: 25%;
        text-align: center;
        color: #fff;
        /* padding-bottom: 15px; */
    }

.thc-result-mainbox {
    background-color: #001a3d;
    padding: 15px 20px;
    border-radius: 8px;
}

.border-bot-black {
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.shareholder-template-box .border-bot-black {
    width: 100%;
    border-bottom: 1px solid #000;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

.rejected {
    color: #ff0000;
}

.approved {
    color: #00fa4b;
}

.transac-history-result p.rejected {
    color: #ff0000;
}

.transac-history-result p.approved {
    color: #00fa4b;
}

.downline-search-box {
    background-color: #001a3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    height: 45px;
    border-radius: 8px;
}

    .downline-search-box input {
        font-family: "averta-semibold";
        color: #fff;
        background: transparent;
        border: 0px;
        box-shadow: unset;
        justify-content: space-between;
        width: 100%;
    }

    .downline-search-box .first {
        width: 100%;
    }

.bg-darkblue {
    background: #001a3d;
}

.downline-col {
    display: flex;
}

#downline-col {
    display: flex;
    justify-content: center;
    align-items: center;
}

    #downline-col > div {
        width: 50%;
        text-align: center;
    }

    #downline-col .first p {
        padding: 5px;
    }

    #downline-col .first {
        border-right: 1px solid #143561;
        padding-right: 5px;
    }

#downline-search-box-main .template-box {
    height: 100%;
    padding: 10px 25px;
}

.breadcrumbs li {
    display: inline-block;
    color: #fff;
}

    .breadcrumbs li:not(:last-child):after {
        content: "/";
        display: inline-block;
        padding-left: 10px;
        margin-right: 10px;
    }

    .breadcrumbs li a {
        cursor: pointer;
    }

.kpi-account {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.kpi-accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.kpi-accordion-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.kpi-accordion-title .img2 {
    display: none;
}

.kpi-accordion-title[aria-expanded="true"] .img1 {
    display: none;
}

.kpi-accordion-title[aria-expanded="true"] .img2 {
    display: block;
}

#downline-kpi-table .transac-history-result > div {
    width: 20%;
    text-align: center;
    color: #fff;
}

#game-record-box .transac-history-result > div {
    width: 14.28%;
}

#wallet-statement-box .transac-history-result > div {
    width: 20%;
}

.rollover-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

    .rollover-box .small-btn {
        padding: 0px;
    }

.reward-box {
    background-color: #001a3d;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 30px;
    height: 45px;
    border-radius: 8px;
    margin-bottom: 12px;
}

.reward-note {
    /* padding: 9px 0px 9px 30px; */
    padding: 0px 0px 9px 30px;
}

.reward-tnc {
    color: #ffc159;
}

.reward-search-divider {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

    .reward-search-divider .rs-dash {
        margin-right: 15px;
    }

    /* .reward-search-divider .rs-second-input {
	width: 100%;
} */

    .reward-search-divider .rs-first-input,
    .reward-search-divider .rs-second-input {
        font-size: 13px;
    }

    .reward-search-divider .transac-history-search-box .th-search-btn {
        width: 50px;
        text-align: right;
        border-left: 1px solid #fff;
        margin-left: 0px;
    }

    .reward-search-divider > .first {
        width: 40%;
    }

    .reward-search-divider > .second {
        width: 60%;
    }

#reward-results-bottom .template-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: transform 0.2s;
    margin-top: 10px;
}

#reward-results-bottom a:hover .template-box {
    transform: scale(1.01);
}

.reward-history-drop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    max-width: 60px;
    margin: auto;
    margin-bottom: 1rem;
}

    .reward-history-drop .img2 {
        display: none;
    }

    .reward-history-drop[aria-expanded="true"] .img1 {
        display: none;
    }

    .reward-history-drop[aria-expanded="true"] .img2 {
        display: block;
    }

.reward-grand-total {
    background: #002e6c;
    margin-left: -25px;
    margin-right: -25px;
    margin-bottom: -20px;
    border-radius: 0px 0px 8px 8px;
    padding: 10px 25px;
}

a:hover {
    text-decoration: unset !important;
}

.popular-section .load-more-btn {
    background: linear-gradient( 180deg, rgb(252 235 55) 0%, rgba(253, 182, 46, 1) 56%, rgba(254, 126, 37, 1) 100% );
}

#bonus-report-box .reward-grand-total {
    padding: 10px 20px;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: -15px;
}

#account-section .wallet-main-boxes {
    display: block;
    padding: 18px 25px;
    height: unset;
}

#account-section hr {
    background: #000;
}

#account-section #my-tabs {
    border-bottom: 1px solid transparent;
}

.resend-code {
    position: absolute;
    right: 15px;
    top: 48px;
    cursor: pointer;
}

#settings-language.wallet-main-boxes {
    display: block;
    padding: 18px 25px;
    height: unset;
}

.setting-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#setting-lang ul {
    margin-left: 50px;
}

#settings-language > .first {
    justify-content: space-between;
}

    #settings-language > .first .img2 {
        display: none;
    }

    #settings-language > .first[aria-expanded="true"] .img1 {
        display: none;
    }

    #settings-language > .first[aria-expanded="true"] .img2 {
        display: block;
    }

.referral-copy-box {
    border: 1px solid #fff;
    max-width: 500px;
    margin: auto;
    border-radius: 100px;
    position: relative;
    padding: 13px 30px;
}

.referral-copy {
    position: absolute;
    right: 0;
    top: 1px;
    width: 44px;
    cursor: pointer;
    padding-top: 26px;
}

.referral-game-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px 30px;
}

.referral-edit-box {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
}

.referral-edit-details {
    display: flex;
    align-items: center;
}

    .referral-edit-details .first {
        min-width: 70px;
    }

.referral-add-minus {
    display: flex;
    align-items: center;
    margin: 0 5px;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 5px 20px;
}

    .referral-add-minus input {
        text-align: center;
        width: 100px;
        color: #ffc159;
        background: transparent;
        border: 0px;
    }

    .referral-add-minus select {
        text-align: center;
        width: 100px;
        color: #ffc159;
        background: transparent;
        border: 0px;
        appearance: none;
    }

/* .referral-add-minus span {
  cursor: pointer;
} */
.referral-max-box {
    cursor: pointer;
    background: #ffc159;
    color: #002e6c;
    padding: 9px 10px;
    border-radius: 100px;
    margin-left: 15px;
}

.download-img-box {
    display: flex;
    column-gap: 50px;
    align-items: center;
    justify-content: center;
}

.video-banner {
    margin: -20px -25px 0px;
}

.video-search-icon {
    position: absolute;
    right: 30px;
    top: 11px;
}

#video-tutorial .row > [class^="col"] {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 30px;
}

.video-box {
    background: #001a3d;
    border-radius: 10px;
    height: 100%;
}

    .video-box .second {
        padding: 5px 30px 13px;
    }

.play-video {
    position: absolute;
    left: 0px;
    top: 0px;
    /* transform: translate(-50%,-50%); */
    height: 100%;
    width: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    background: #00000061;
    border-radius: 10px;
    cursor: pointer;
}

.video-box:hover .play-video {
    display: flex;
}

.sort-search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .sort-search-box > div:last-child {
        width: 100%;
        max-width: 300px;
    }

    .sort-search-box .form-white-input {
        border: 0px;
    }

.sort-games-section .game-box {
    height: 100%;
}

.sort-games-section > div > div > div > div.font-thick {
    padding-left: 10px;
}

.sort-game-image {
    /* height: calc(100% - 58px); */
    height: 150px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 15px;
}

/* .sort-game-image img {
  height: 100%;
  object-fit: cover;
} */

.img-radius {
    border-radius: 15px;
}

.logo-image-size {
    height: 150px;
    width: 100%;
}

.banner-slider-section {
    /* width: 1140px; */
    /* height: 275px; */
    margin: auto;
    width: 100%;
}

.banner-img-radius {
    border-radius: 25px;
}

.cursor-pointer {
    cursor: pointer;
}

.custom-date-select .css-g1d714-ValueContainer {
    padding: 0px;
}

.custom-date-select .css-1ty6pqj-control {
    border: 0px;
}

.custom-date-select .css-tlfecz-indicatorContainer {
    padding: 0px;
}

.custom-date-select svg {
    display: none;
}

.custom-date-select .css-tlfecz-indicatorContainer {
    background: url(../img/assets/drop.svg);
    width: 18px;
    height: 12px;
    background-size: 100% 100%;
}

.custom-date-select .css-1gtu0rj-indicatorContainer {
    background: url(../img/assets/up.svg);
    width: 18px;
    height: 12px;
    background-size: 100% 100%;
    padding: 0px;
}

.custom-date-select .css-1cyyekq-control {
    border: 0px !important;
}

.custom-date-select .css-26l3qy-menu {
    color: #001a3d;
}

.custom-date-select .css-efrf4g-control {
    border: 0px !important;
}

#vip-event-1 .btn:hover {
    color: #fff;
}

#vip-event-1 .wallet-main-boxes .first {
    justify-content: space-between;
    width: 95%;
}

#vip-event-1 .input-group.mb-3 {
    margin-bottom: 0px !important;
}

#vip-event-1 .form-group {
    margin-bottom: 0px !important;
}

.vip-c-history-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.vip-events-with-accordion .accordion-header button {
    padding: 0px;
    background: transparent;
    border: 0px;
    width: 100%;
    margin: 0px;
}

.vip-events-with-accordion .accordion-header {
    margin-bottom: 0px;
}

.vip-events-with-accordion .wallet-main-boxes {
    min-height: 60px;
    height: auto;
    padding: 15px 25px;
}

.dropzone {
    border-radius: 10px;
    border: 1px dashed #3369FF !important;
}

    .dropzone h4 {
        color: #ffffff69;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 11px;
    }

    .dropzone .thumbsContainer {
        position: relative;
    }

    .dropzone .thumb img {
        background-color: #000;
        max-width: 100%;
    }

#tngdeposit .fa-trash {
    z-index: 2;
    position: relative;
}

.vip-events-with-accordion {
    /* color: #fff; */
}

select option {
    color: #001a3d;
    background: #fff;
}

.login-popup-wrapper .custom-input-style-1 {
    margin-bottom: 15px;
}

.header-search-dropdown {
    position: absolute;
    width: fit-content;
    /* margin-top: 5px; */
    top: 35px;
    background: rgb(0 0 0 / 50%);
    font-weight: bold;
    border-radius: 10px;
    text-align: left;
    padding: 10px;
    line-height: 1.7;
    height: 200px;
    overflow-y: scroll;
    white-space: nowrap;
    z-index: 999;
}

    .header-search-dropdown::-webkit-scrollbar {
        display: none;
    }

.search-not-empty .hsearch-input {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.search-not-empty .hsearch-btn {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.main-nav li .header-search-dropdown a {
    font-weight: unset;
    font-family: "averta-thin";
}

    .main-nav li .header-search-dropdown a:hover {
        color: #ffc159 !important;
    }

.pin-number {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    max-width: 400px;
    border-radius: 50px;
    /* padding-bottom: 0px; */
    /* height: 220px; */
    height: fit-content;
    z-index: 9999;
}

.settings-pin {
    max-width: 400px;
    margin: auto;
    border-radius: 50px;
    padding: 35px 0px 0px;
    /* margin-top: 7em; */
}

.settings-pin .modal-header {
    padding: 0px;
    border: 0px;
}

.settings-pin .modal-header .close {
    position: absolute;
    top: 15px;
    right: 30px;
    opacity: 1;
}

.settings-pin .modal-header .close span {
    font-size: 2rem;
    color: rgb(0, 46, 108);
}

.modal-position {
    position: unset !important;
}

/* #sort-section img {
    border-radius: 30px;
} */

.css-1f71ezs-control {
    min-height: 45px !important;
    background: #02214a url(../img/assets/drop.svg) no-repeat 95% center !important;
}

#internal-transfer .css-1qkv4rq-placeholder {
    font-size: 11px !important;
    font-weight: unset !important;
}

#internal-transfer .css-18t12i7-control {
    min-height: 45px !important;
    background: #02214a url(../img/assets/drop.svg) no-repeat 95% center !important;
}

#internal-transfer .css-tj5bde-Svg {
    display: none !important;
}

#internal-transfer .css-o01cwa-singleValue {
    font-size: 11px !important;
    font-weight: unset !important;
}

#internal-transfer .css-g1d714-ValueContainer {
    padding: 0px !important;
}

#footer .select-box .dropdown #dropdown-basic {
    width: 160px !important;
}

#footer .select-box .dropdown .dropdown-toggle::after {
    margin-left: 20px !important;
}

.css-4qx0hu-singleValue {
    padding-left: 20px !important;
    font-size: 11px !important;
    font-weight: unset !important;
}

.css-g1d714-ValueContainer {
    padding: 0px !important;
}

.css-aalhqv-placeholder {
    padding-left: 20px !important;
    font-size: 11px !important;
    font-weight: unset !important;
}

.css-tlfecz-indicatorContainer {
    display: none !important;
}

.css-1gtu0rj-indicatorContainer {
    display: none !important;
}

.css-2b097c-container, .downline-games-contoller .css-2b097c-container {
    min-height: 45px !important;
    background: #02214a url(../img/assets/drop.svg) no-repeat 95% center !important;
}

.custom-date-select .css-2b097c-container {
    min-height: 45px !important;
    /* background: #02214a url(../img/assets/drop.svg) no-repeat 95% center !important; */
    background: transparent url(../img/assets/drop.svg) no-repeat 100% center !important;
}


.custom-date-select .css-12cf7sr-control {
    border-color: unset !important;
    border: none !important;
    box-shadow: none !important;
}

    .custom-date-select .css-12cf7sr-control:hover {
        border: none !important;
        border-color: unset !important;
    }

.modal-backdrop {
    width: 100%;
    height: 100%;
}

.modal.show .modal-dialog {
    margin: auto;
}

.settings-modal .modal.show .modal-dialog {
    width: 90%;
}

#summary-downline-section .my-income-card {
    width: unset;
}

#summary-downline-section .card-wrapper {
    padding: 0px;
    background: transparent;
}

#summary-downline-section .hierarchy-line a {
    color: #ffc159 !important;
}

#summary-downline-section .table-kpi-summary {
    font-weight: unset !important;
}

#summary-downline-section .table thead tr th {
    font-weight: unset !important;
    font-family: "averta-semibold" !important;
}

.has-downline {
    color: #2961FF !important;
}

.card-package .table .subtotal td {
    background: #002E6C;
}

.card-package .table .globaltotal td {
    background: #002E6C;
}

#summary-downline-section .table td, #summary-downline-section .table th {
    border: 0px;
}
/*///end side menu//*/

/* ///game categories/// */
#Esport-section1 {
    margin-bottom: -10px;
}

.esport-banner {
    position: relative;
}

.esport-text {
    position: absolute;
    top: 50%;
    left: 7%;
    transform: translate(0, -50%);
}

.esport-bird {
    position: absolute;
    top: 35%;
    left: 40%;
}

.esport-joker {
    position: absolute;
    top: 1%;
    right: 26%;
}

.esport-guy {
    position: absolute;
    top: 1%;
    right: -2%;
}

#Esport-section2 {
    /* padding: 10em 0px; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 680px;
}

    #Esport-section2 .container {
        height: 100%;
    }

    #Esport-section2 .row {
        align-items: center;
        height: 100%;
    }

    #Esport-section2 .slick-slider {
        width: 100%;
        background-color: transparent;
    }

    #Esport-section2 > .slick-slider {
        top: 50%;
        transform: translate(0, -50%);
    }

    #Esport-section2 .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
    }

    #Esport-section2 .slick-slide {
        float: none;
        display: inline-block;
        vertical-align: middle;
        padding: 10px 0px;
        margin: 10px;
        transition: all 0.3s ease;
        height: auto;
        text-align: center;
    }

        #Esport-section2 .slick-slide img {
            max-width: 70%;
            opacity: .8;
        }

    #Esport-section2 .slick-current img,
    #Esport-section2 .slick-center img {
        max-width: 100%;
        opacity: 1;
    }

    #Esport-section2 .slick-current,
    #Esport-section2 .slick-center {
        padding: 0px;
    }

    #Esport-section2 .slick-slide .esport-item:hover {
        background: url(../img/esport/hover.png);
        background-size: cover;
        animation: moveIt 5s linear infinite;
    }

    #Esport-section2 .slick-slide .esport-item.game-um:hover {
        background: none;
    }

.esport-item.game-um {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
}

.esport-item {
    cursor: pointer;
}

.single-item {
    width: 400px !important;
    height: auto;
}

#Esport-section2 .slick-slide .esport-item.single-item:hover {
    background: url(../img/esport/hover.png);
    background-size: cover;
    animation: moveIt 5s linear infinite;
}

#Esport-section2 .slick-slide .esport-item.game-um.single-item:hover {
    background: none;
}

.esport-item.game-um.single-item {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
}

.opacity-7 {
    opacity: .7;
}

.opacity-6 {
    opacity: .6;
}

@keyframes moveIt {
    from {
        background-position: bottom left;
    }

    to {
        background-position: top right;
    }
}

.rng-banner {
    position: relative;
}

#rng-section1 {
    overflow: hidden;
}

.rng-text {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(0, -50%);
}

.rng-playnow, .rng-girl {
    cursor: pointer;
}

.rng-text .rng-playnow:hover .rng-pl-1 {
    display: none;
}

.rng-text .rng-playnow .rng-pl-2, .rng-text .rng-playnow:hover .rng-pl-2.game-um {
    display: none;
}

.rng-text .rng-playnow:hover .rng-pl-2, .rng-text .rng-playnow:hover .rng-pl-1.game-um {
    display: block;
    /* background: #CE1449;
border-radius: 50px;
-webkit-transition: background-color 0.9s;
-moz-transition: background-color 0.9s;
-o-transition: background-color 0.9s;
transition: background-color 0.9s; */
}

.rng-pl-1.game-um {
    filter: grayscale(1);
    cursor: not-allowed;
    pointer-events: none;
    user-select: none;
}

.rng-dice1 {
    position: absolute;
    bottom: 0px;
}

.rng-dice2 {
    position: absolute;
    top: 23%;
    left: 10%;
}

.rng-chip1 {
    position: absolute;
    top: 17.8%;
    left: 38.2%;
}

.rng-robot {
    position: absolute;
    top: 7%;
    right: 2%;
}

.rng-ball {
    position: absolute;
    top: -1%;
    right: 3%;
}

.rng-chip2 {
    position: absolute;
    right: 0%;
    bottom: -20px;
}

#rng-section2 {
    position: relative;
}

.rng-girl {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.rng-girl-hover {
    display: none;
}

.rng-girl:hover img {
    display: none;
}

.rng-girl:hover .rng-girl-hover, .rng-girl.game-um:hover .rng-girl-before-hover {
    display: block;
}

.rng-girl.game-um:hover .rng-girl-hover {
    display: none;
}

.rng-girl.game-um .rng-girl-before-hover {
    filter: grayscale(1);
    pointer-events: none;
    user-select: none;
}

.rng-girl.game-um {
    position: absolute !important;
}

.rng-girl:hover .um-icon img {
    display: block !important;
}

#Lottery-section {
    margin-bottom: -10px;
}

.lottery-card {
    position: absolute;
    bottom: 8%;
    left: 4%;
}

.lottery-dice {
    position: absolute;
    top: 19%;
    left: 39%;
}

.lottery-gold {
    position: absolute;
    bottom: 23%;
    right: 38%;
}

.lottery-green {
    position: absolute;
    top: 9%;
    right: 2%;
}

.lottery-red {
    position: absolute;
    bottom: 3%;
    right: 1%;
}

.poker-center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

    .poker-center.game-um {
        filter: grayscale(1);
        pointer-events: none;
        user-select: none;
        position: absolute;
    }

.poker-green {
    position: absolute;
    bottom: 35%;
    left: 21%;
}

.poker-lavender {
    position: absolute;
    bottom: 15%;
    right: 20%;
}

.poker-red {
    position: absolute;
    top: 13%;
    right: 30%;
}

#Casino-section2 {
    padding: 10em 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.casino-item {
    cursor: pointer;
}

    .casino-item.game-um {
        pointer-events: none;
        user-select: none;
        cursor: not-allowed;
    }

.casino-card {
    position: absolute;
    top: 25%;
    left: 2%;
}

.casino-flower {
    position: absolute;
    top: 10%;
    right: 34%;
}

.casino-heart {
    position: absolute;
    bottom: 10%;
    right: 36%;
}

.casino-diamond {
    position: absolute;
    top: 5%;
    right: 3%;
}

.casino-spade {
    position: absolute;
    bottom: 9%;
    right: 3%;
}

#width-video {
    width: 100%;
}

#casino-section {
    margin-bottom: -10px;
}

#poker-section1 {
    margin-bottom: -10px;
}

.timer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.clock-box {
    font-family: "averta-bold";
    font-size: 34px;
    color: #CB93FC;
}

    .clock-box > div {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        line-height: 1;
    }

        .clock-box > div > .per-time-box {
            background-color: #1300298a;
            width: 45px;
            border-radius: 10px;
            padding: 10px 0px;
        }

.clock-title {
    color: #CB93FF;
    font-family: "averta-bold";
}

.sport-timer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: unset;
    bottom: 8%;
}

.timer-text {
    color: #A156E6;
    margin-top: 10px;
    text-transform: capitalize;
}

.timer-diamond {
    padding-bottom: 20px;
}

#sports-section2 {
    min-height: 410px;
    display: flex;
    align-items: center;
    background-size: cover;
}

.sport-game-space {
    padding-bottom: 18px;
}

#sports-section3 {
    background-size: cover;
    padding: 3em 0px 3em;
}

.sport-group-box {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    padding: 40px 0px;
    color: #fff;
    font-family: "averta-bold";
}

.sports-group-title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.sport-grouplist-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 15px 30px;
}

.sport-grouplist-image {
    min-width: 35px;
    max-width: 35px;
}

.game-um {
    position: relative;
}

    .game-um > img {
        filter: grayscale(.7);
    }

.um-icon {
    display: none;
}

.game-um .um-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 90px;
    transform: translate(-50%,-50%);
}

.hover-show-second-img .show-img2 {
    display: none;
}

.casino-diamond {
    position: absolute;
    top: 5%;
    right: 3%;
}

.casino-spade {
    position: absolute;
    bottom: 9%;
    right: 3%;
}

#width-video {
    width: 100%;
}

#casino-section {
    margin-bottom: -10px;
}

#poker-section1 {
    margin-bottom: -10px;
}

.timer-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.clock-box {
    font-family: "averta-bold";
    font-size: 34px;
    color: #CB93FC;
}

    .clock-box > div {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 2px;
        line-height: 1;
    }

        .clock-box > div > .per-time-box {
            background-color: #1300298a;
            width: 45px;
            border-radius: 10px;
            padding: 10px 0px;
        }

.clock-title {
    color: #CB93FF;
    font-family: "averta-bold";
}

.sport-timer {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: unset;
    bottom: 8%;
}

.timer-text {
    color: #A156E6;
    margin-top: 10px;
}

.timer-diamond {
    padding-bottom: 20px;
}

#sports-section2 {
    padding: 30px 0px;
    background-size: cover;
}

.sport-game-space {
    padding-bottom: 18px;
}

#sports-section3 {
    background-size: cover;
    padding: 3em 0px 3em;
}

.sport-group-box {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100%;
    padding: 40px 0px;
    color: #fff;
    font-family: "averta-bold";
}

.sports-group-title {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 15px;
}

.sport-grouplist-box {
    display: flex;
    align-items: center;
    column-gap: 15px;
    padding: 15px 30px;
}

.sport-grouplist-image {
    min-width: 35px;
    max-width: 35px;
}

.game-um {
    position: relative;
}

    .game-um > img {
        filter: grayscale(.7);
    }

.um-icon {
    display: none;
}

.game-um .um-icon {
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    max-width: 90px;
    transform: translate(-50%,-50%);
}

.hover-show-second-img .show-img2 {
    display: none;
}

.hover-show-second-img:hover .show-img1 {
    display: none;
}

.hover-show-second-img:hover .show-img2 {
    display: inline-block;
}

.hover-show-second-img:hover .game-um .show-img1 {
    display: inline-block;
}

.hover-show-second-img:hover .game-um .show-img2 {
    display: none;
}

.margin-negative {
    margin: 0px -10px
}
/* ///end game categories//// */

/* ///richwin admin/// */
#richwin-section {
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    padding: 10em 0px;
}

.richwin-box {
    max-width: 350px;
    margin: auto;
}

#richwin-section h2 {
    font-size: 34px;
    font-weight: 900;
}

    #richwin-section h2 span {
        font-size: 24px;
        font-weight: 300;
    }

.input-bg-white {
    background: #fff;
    color: #000;
}

    .input-bg-white::-webkit-input-placeholder {
        color: #858585;
    }

.recaptcha-text {
    color: #000;
    border-radius: 8px;
    background: #E2E2E2;
    height: 45px;
    display: inline-block;
    font-weight: 900;
    font-family: "Roboto", sans-serif;
    font-size: 23px;
    padding: 6px 30px;
    letter-spacing: 2px;
}

.richwin-hr {
    height: 2px;
    width: 100%;
    border: 0px;
    background-color: #fff;
    margin: 35px 0px;
}

.white-long-btn {
    background: #fff;
    border-radius: 8px;
    border: 0px;
    font-family: "Roboto", sans-serif;
    color: #000;
    font-weight: 900;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    font-size: 18px;
    width: 100%;
}
/* ///end richwin admin/// */

/* ////download page//// */
#download-banner-section {
    background: url(../img/download.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: right;
    padding: 19em 0px 24em;
}

    #download-banner-section .container {
        height: 100%;
    }

    #download-banner-section .row {
        align-items: center;
        height: 100%;
    }

    #download-banner-section h2 {
        font-size: 65px;
    }

.download-buttons {
    display: flex;
    align-items: center;
    column-gap: 20px;
    margin-top: 40px;
}

    .download-buttons .small-btn {
        display: flex;
        display: flex;
        align-items: center;
        padding: 3px 0px;
        column-gap: 15px;
        width: 200px;
        justify-content: center;
    }

        .download-buttons .small-btn:hover {
            color: #fff;
            background: #0067b1;
        }

.download-category-slider .slick-prev:before {
    content: " ";
    background: url(../img/prev.png) no-repeat center;
    box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -moz-box-shadow: 0px 0px 30px -3px rgba(225, 176, 48, 0.75);
    border: 1px solid #e1b030;
    width: 33px;
    height: 34px;
    display: block;
    border-radius: 30px;
    position: absolute;
    right: 0;
    top: 0;
}

.download-category-slider .slick-next:before {
    content: " ";
    background: url(../img/next.png) no-repeat center;
    box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -webkit-box-shadow: 0px 0px 30px -3px rgb(225 176 48 / 75%);
    -moz-box-shadow: 0px 0px 30px -3px rgba(225, 176, 48, 0.75);
    border: 1px solid #e1b030;
    width: 33px;
    height: 34px;
    display: block;
    border-radius: 30px;
}

.download-category-slider .slick-arrow {
    position: absolute;
}

.download-category-slider .slick-next {
    z-index: 2;
    right: -30px;
}

.download-category-slider .slick-prev {
    z-index: 2;
    left: -30px;
}

.download-category-item {
    text-align: center;
    padding: 0px 10px;
}

.download-main-title:after {
    content: " ";
    display: block;
    background: #ffc159;
    height: 3px;
    width: 70px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 40px;
}

#download-popular-section .small-btn {
    max-width: 120px;
    margin: auto;
    padding: 5px 0px;
    font-family: "averta-semibold";
}

    #download-popular-section .small-btn:hover {
        color: #fff;
        background: #0067b1;
    }

#download-popular-section .game-box {
    margin-bottom: 30px;
}

#download-feature-section {
    background: url(../img/download/feature-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.download-btn-white .small-btn {
    background-color: #fff;
    color: #1a2758;
    font-size: 16px;
    width: 13vw;
    width: 165px;
    padding: 3px 10px;
}

.download-bottom-content {
    position: absolute;
    top: 30%;
    right: 8%;
}

    .download-bottom-content h3 {
        font-size: 25px;
        margin-bottom: 30px;
    }

    .download-bottom-content p {
        font-size: 16px;
    }

    .download-bottom-content .download-buttons {
        margin-top: 35px;
    }

.download-header-box {
    display: flex;
    align-items: center;
}

.download-header .main-nav li a {
    font-size: 20px;
}

    .download-header .main-nav li a:hover {
        color: #ffc159;
    }

.download-header .download-register-btn {
    font-size: 16px;
    background-color: transparent;
    color: #fff;
    border: 1px solid #ffc159;
    width: unset;
    padding: 3px 25px;
    border-radius: 100px;
}

    .download-header .download-register-btn:hover {
        background-color: #ffc159;
    }

.download-banner-image {
    display: none;
}
/* /////end download page/// */

/* ///toogle//*/
.switch {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 56px;
    height: 24px;
    background-color: transparent;
    border-radius: 18px;
    color: #fff;
    /* border:1px solid #fff; */
    cursor: pointer;
    margin-bottom: 0px;
}

.switch-input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.switch-label {
    position: relative;
    display: block;
    height: inherit;
    font-size: 10px;
    text-transform: uppercase;
    border-radius: inherit;
    border: 1px solid #fff;
}

    .switch-label:before,
    .switch-label:after {
        position: absolute;
        top: 50%;
        margin-top: -0.5em;
        line-height: 1;
    }

    .switch-label:before {
        content: attr(data-off);
        right: 7px;
        color: #fff;
        font-size: 11px;
    }

    .switch-label:after {
        content: attr(data-on);
        left: 11px;
        color: white;
        opacity: 0;
        font-size: 11px;
    }

.switch-input:checked ~ .switch-label {
    background: #fff;
}

    .switch-input:checked ~ .switch-label:before {
        opacity: 0;
    }

    .switch-input:checked ~ .switch-label:after {
        opacity: 1;
        color: #02285a;
    }

.switch-handle {
    position: absolute;
    top: 2px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: white;
    border-radius: 10px;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    -webkit-transition: left 0.15s ease-out;
    -moz-transition: left 0.15s ease-out;
    -o-transition: left 0.15s ease-out;
    transition: left 0.15s ease-out;
}

.switch-input:checked ~ .switch-handle {
    left: 33px;
    background: #02285a;
}

.switch .am-label {
    position: absolute;
    left: -25px;
    color: #fff;
    top: 3px;
}

.switch .pm-label {
    position: absolute;
    right: -25px;
    color: #1f5196;
    top: 3px;
}

.switch-blue > .switch-input:checked ~ .switch-label {
    background: #002e6c;
    border: 1px solid #002e6c;
}

.switch-blue > .switch-input:checked ~ .switch-handle {
    background: #02214a;
}

.switch-blue > .switch-input:checked ~ .pm-label {
    color: #fff;
}

.switch-blue > .switch-input:checked ~ .am-label {
    color: #1f5196;
}

.switch-blue > .am-icon {
    position: absolute;
    right: 7px;
}

.switch-blue > .pm-icon {
    position: absolute;
    left: 8px;
}

.switch-blue > .switch-input:checked ~ .pm-icon {
    z-index: 1;
}
/* ///end toogle// */

/*/////foooter////*/
footer {
    background: #04102a;
    background-position: center;
    background-size: 100% 100%;
    padding: 60px 0px 40px;
    color: #fff;
    font-family: "averta-thin";
    font-size: 14px;
}

    footer a {
        color: #fff;
    }

        footer a:hover {
            color: #ffc159;
            text-decoration: unset;
        }

    footer .f-links {
        vertical-align: middle;
    }

        footer .f-links li {
            /*padding: 0px 7px;*/
            display: inline-block;
        }

            footer .f-links li a:after {
                content: ' ';
                margin-right: 15px;
                padding-right: 15px;
                border-right: 1px solid #fff;
                display: inline-block;
                height: 10px;
            }

            footer .f-links li:last-child a:after {
                display: none;
            }

    footer p {
        font-size: 14px;
    }

    footer h1 {
        font-family: "averta-semibold";
        font-size: 15px;
        color: #ffc159;
        margin-bottom: 20px;
    }

    footer select {
        background: #02214a url(../img/selectdown.png) no-repeat 90% center;
        color: #fff;
        padding: 8px 20px 8px 20px;
        min-width: 180px;
        border-radius: 13px;
        border: 0px;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
    }

    /* footer select option { 
	  color: black; 
  } */
    footer .select-box {
        position: relative;
        display: inline-block;
    }

.footer-payment {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.payment-method {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    max-width: 450px;
}

    .payment-method li {
        list-style-type: none;
        display: inline-block;
        text-align: center;
        padding: 0 10px;
        margin: 0 4px 8px 0;
        cursor: default;
        flex: 1 0 auto;
        margin-bottom: 20px;
    }

    .payment-method:after {
        content: '';
        display: block;
        flex: 999;
    }

.footer-social-media ul {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.footer-games {
    text-align: center;
}

    .footer-games div {
        display: inline-block;
        text-align: left;
    }

.copy-right {
    margin-top: 2em;
}

.footer-gld {
    max-width: 365px;
    margin-bottom: 0px;
}

.footer-hr {
    background: #ffffff63;
    width: 100%;
    margin: 40px 0px;
}

footer ul {
    margin-bottom: 0px;
}

footer .copy-right h4 {
    display: flex;
    align-items: center;
    column-gap: 9px;
}

.version-number {
    letter-spacing: 1.0px;
    font-size: 13px;
}
/*/////end footer//*/

/*//download*/
#file-upload {
    display: none;
}

#file-drag {
    border: 1px solid #fff;
    border-radius: 8px;
    color: #555;
    cursor: pointer;
    display: block;
    padding: 3em;
    text-align: center;
    transition: background 0.3s, color 0.3s;
    width: 100%;
}

#file-upload-form .image-col {
    width: 100%;
}

#file-drag span {
    display: block;
    margin-bottom: 10px;
}

.file-text {
    color: #ffffff9e;
}

#file-drag:hover {
    background: #11111111;
}

#file-drag:hover,
#file-drag.hover {
    border-color: #fff;
    border-style: dashed;
    /*box-shadow   : inset 0 3px 4px #888;*/
    color: #3070a5;
}

#file-drag svg {
    margin-bottom: 10px;
}

#file-upload-form {
    margin: auto;
    width: 100%;
    position: relative;
}

#messages {
    border: 1px solid #b3c4cc60;
    border-radius: 10px;
    margin: 1em 0;
    padding: 20px;
    display: none;
    width: 100%;
}

.result {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.file-info {
    display: inline-flex;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    color: #668899;
}

.data-info {
    margin-left: 20px;
}

.result .filename {
    display: block;
    font-style: normal;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: -0.015em;
    text-decoration-line: underline;
    color: #fff;
}

.result .filesize {
    display: block;
    margin-top: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: -0.015em;
    color: #b3c4cc;
}

#del-file {
    cursor: pointer;
}

.show-mobile {
    display: none;
}

.show-desktop {
    display: block;
}
/*///end download//*/

/* ///Pin Number Modal /// */

.btn.btn-brand {
    background: linear-gradient(180deg, #ffcf7d, #fe9f00);
    font-weight: bold;
    color: #002e6c;
    border: 0;
}

    .btn.btn-brand:hover,
    .btn.btn-brand:focus {
        opacity: 0.8;
    }

.btn.btn-brand-gradient {
    background: linear-gradient(180deg, #ffcf7d, #fe9f00);
    color: #002e6c;
    font-weight: bold;
    border: 0;
}
/* ///end Pin Number Modal /// */

/* ///Reward Page/// */
#reward-results-bottom .template-box {
    justify-content: center;
}

#reward-results-bottom .gpn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 54px;
}
/* ///End Reward Page/// */

/* PUWA */

.spinner-border {
    border-color: white;
    border-right-color: transparent;
}

#tr .thr-titles {
    width: 0.1px;
}

.thr-item-fromWallet,
.thr-item-toWallet,
.thr-item-bonus,
.thr-item-fromWalletBalanceBefore,
.thr-item-fromWalletBalanceAfter,
.thr-item-toWalletBalanceBefore,
.thr-item-toWalletBalanceAfter {
    color: #ffffff;
    width: 0.1px;
}

#tr .thr-item-date {
    width: 3.5rem;
}

#dr td, #wr td, #tr td {
    border-bottom: 1px solid #ffffff;
    padding: 5px 0;
}

.sport-timer.sport-timer-homepage {
    /* top: 78%;
  bottom: unset; */
    bottom: 10%;
}
/* END PUWA */

/* JUNWEI */

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #082c6c; /* Dark blue */
    border-radius: 150%;
    animation: spinloader 2s linear infinite;
    padding: 20px;
}

    .loader img {
        height: 50px;
        width: 50px;
        animation: spinlogo 2s linear infinite;
    }

@keyframes spinloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinlogo {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.loader-font {
    font-family: Franklin Gothic Medium;
    padding-top: 7px;
    font-size: 22px;
}

/* END JUNWEI */

/* //sport bracket// */
.sports-bracket-title {
    position: relative;
    width: 100%;
}

.sports-bracket-box {
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    position: relative;
    width: 100%;
}

.sports-finals-symbol {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    font-size: 23px;
    color: #fff;
    font-family: "averta-bold";
    display: flex;
    justify-content: center;
    align-items: center;
}

.sports-bracket-left .bracket1 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.sports-bracket-left .bracket2 {
    display: flex;
    align-items: center;
    margin: 15px;
}

.sports-team-box {
    /* background: url(../img/sports/team-bg.png); */
    /* background-size: 100% 100%; */
    /* background-repeat: no-repeat; */
    margin: 10px;
    position: relative;
    display: flex;
    align-items: center;
    font-family: "averta-bold";
    column-gap: 5px;
    color: #fff;
    /* width: 135px;
  height: 52px; */
    line-height: 1;
    /* padding-left: 15px; */
}

    .sports-team-box .sport-team-id {
        font-size: 11px;
    }

    .sports-team-box .sport-team-flag {
        width: 23px;
    }

.sport-team-country {
    font-size: 10px;
}

.sports-bracket-left .bracket3 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
}

    .sports-bracket-left .bracket3:after {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        right: -15px;
        left: unset;
        top: 50%;
        transform: translate(0, -50%);
    }

.sports-bracket-left .bracket4 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
    margin: 15px;
}

    .sports-bracket-left .bracket4:before {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .sports-bracket-left .bracket4:after {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        right: -15px;
        left: unset;
        top: 50%;
        transform: translate(0, -50%);
    }

.sports-bracket-left .bracket1-1 {
    position: relative;
}

    .sports-bracket-left .bracket1-1:after {
        content: ' ';
        width: 1px;
        height: 47%;
        background: #ffffff7a;
        position: absolute;
        right: 0;
        left: unset;
        top: 27%;
    }

.sports-bracket-left .bracket1-2 {
    position: relative;
}

    .sports-bracket-left .bracket1-2:after {
        content: ' ';
        width: 1px;
        height: 100%;
        background: #ffffff7a;
        position: absolute;
        right: 0;
        left: unset;
        top: 50%;
    }

.sports-bracket-left .bracket1-3 {
    position: relative;
}

    .sports-bracket-left .bracket1-3:before {
        content: ' ';
        width: 10px;
        height: 1px;
        background: #ffffff7a;
        position: absolute;
        right: -10px;
        left: unset;
        top: -47%;
    }

    .sports-bracket-left .bracket1-3:after {
        content: ' ';
        width: 1px;
        height: 100%;
        background: #ffffff7a;
        position: absolute;
        right: 0;
        left: unset;
        bottom: 50%;
    }

.sports-bracket-left .bracket5 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
    margin: 15px;
}

.bracket-left-box {
    position: relative;
    width: 50%;
}

.sports-finals-img {
    background: url(../img/sports/final-bg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    margin: 10px 0px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "averta-bold";
    color: #fff;
    width: 80px;
    padding: 15px 0px;
    line-height: 1;
    flex-direction: column;
    align-items: center;
}

.sports-finals-centrebox {
    padding: 0 20%;
}

.bracket-left-box .sports-finals-box:after {
    content: ' ';
    width: 10px;
    height: 1px;
    background: #ffffff7a;
    position: absolute;
    left: calc(-100% + (25px));
    right: unset;
    top: 50%;
}
/* ///right// */

.sports-bracket-right .bracket1 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.sports-bracket-right .bracket2 {
    display: flex;
    align-items: center;
    margin: 15px;
}

.sports-bracket-right .bracket3 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
}

    .sports-bracket-right .bracket3:after {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(0, -50%);
    }

.sports-bracket-right .bracket4 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
    margin: 15px;
}

    .sports-bracket-right .bracket4:before {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        right: -15px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .sports-bracket-right .bracket4:after {
        content: ' ';
        width: 10px;
        background: #ffffff7a;
        height: 1px;
        position: absolute;
        left: -15px;
        top: 50%;
        transform: translate(0, -50%);
    }

.sports-bracket-right .bracket1-1 {
    position: relative;
}

    .sports-bracket-right .bracket1-1:after {
        content: ' ';
        width: 1px;
        height: 47%;
        background: #ffffff7a;
        position: absolute;
        left: 0;
        top: 27%;
    }

.sports-bracket-right .bracket1-2 {
    position: relative;
}

    .sports-bracket-right .bracket1-2:after {
        content: ' ';
        width: 1px;
        height: 100%;
        background: #ffffff7a;
        position: absolute;
        left: 0;
        top: 50%;
    }

.sports-bracket-right .bracket1-3 {
    position: relative;
}

    .sports-bracket-right .bracket1-3:before {
        content: ' ';
        width: 10px;
        height: 1px;
        background: #ffffff7a;
        position: absolute;
        left: -10px;
        right: unset;
        top: -47%;
    }

    .sports-bracket-right .bracket1-3:after {
        content: ' ';
        width: 1px;
        height: 100%;
        background: #ffffff7a;
        position: absolute;
        left: 0;
        bottom: 50%;
    }

.sports-bracket-right .bracket5 {
    position: relative;
    width: 153px;
    height: 100%;
    background: rgb(65, 17, 90, 0.4);
    border: 0.5px solid #BCEDFF;
    border-radius: 7px;
    margin: 15px;
}

.bracket-right-box {
    position: relative;
    width: 50%;
}

    .bracket-right-box .sports-finals-box {
        display: flex;
        /* flex-flow: row; */
        /* flex-direction: row; */
        flex-direction: column;
        align-items: center;
        position: absolute;
        left: 15%;
        top: 50%;
        transform: translate(0,-50%);
    }

        .bracket-right-box .sports-finals-box:after {
            content: ' ';
            width: 10px;
            height: 1px;
            background: #ffffff7a;
            position: absolute;
            right: calc(-100% + (25px));
            left: unset;
            top: 50%;
        }
/* //end sports bracket// */

/*///////media///*/

@media (max-width: 1250px) {
    .provider-slider {
        padding: 0px 30px;
    }

        .provider-slider .slick-next {
            right: -20px;
        }

        .provider-slider .slick-prev {
            left: -20px;
        }

    .download-category-slider {
        padding: 0px 30px;
    }

        .download-category-slider .slick-next {
            right: -20px;
        }

        .download-category-slider .slick-prev {
            left: -20px;
        }
}

@media (max-width: 1199px) {
    .container {
        width: 95%;
        max-width: unset;
    }

    .main-nav li a {
        font-size: 12px;
    }

    .header-account {
        font-size: 12px;
    }

    .date-lang-box {
        font-size: 12px;
    }

    .deposit-and-claim-box .small-btn {
        min-width: 90px;
    }

    .sort-game-image {
        height: 130px;
    }

    .game-box {
        height: 115px;
    }

    #my-tabs li {
        margin-right: 20px;
    }

    .clock-title {
        font-size: 14px;
    }

    .clock-box {
        font-size: 18px;
    }

    .timer-text {
        font-size: 10px;
    }

    .clock-box > div > .per-time-box {
        width: 35px;
    }

    #sports-section2 {
        min-height: 310px;
    }

    .sports-finals-symbol {
        width: 47px;
        font-size: 15px;
        line-height: 1;
    }
}

@media (max-width: 1090px) {
    .bracket3, .bracket4 {
        width: 105px !important;
    }

    .bracket5 {
        width: 100px !important;
    }

    .sports-team-box .sport-team-flag {
        width: 17px;
    }

    .sports-team-box .sport-team-id {
        font-size: 9px;
    }

    .sport-team-country {
        font-size: 8px;
    }
}

@media (max-width: 1076px) {
    .main-nav li {
        margin-right: 15px;
        margin-bottom: 5px;
    }

    .side-menu-profile h4.mt-2 {
        display: flex;
        align-items: center;
    }

        .side-menu-profile h4.mt-2 a {
            margin-left: 5px !important;
        }

    #reward-history-box .small-btn {
        min-width: 85px;
    }

    #reward-box .small-btn {
        min-width: 85px;
    }
}

@media (max-width: 991px) {

    body {
        font-size: 12px;
    }

    .deposit-bonus-details {
        font-size: 9px !important;
    }

    /* #register-section .container {
    width: 115%;
  } */

    .category-box li a {
        padding: 10px 5px;
    }

    .category-box li {
        min-width: 150px;
    }

    .category-box {
        justify-content: flex-start;
    }

    h6 {
        font-size: 12px;
    }

    h2 {
        font-size: 25px;
    }

    h3 {
        font-size: 20px;
    }

    h4 {
        font-size: 15px;
    }

    .provider-item {
        padding: 0px 20px;
    }

    .download-category-item {
        padding: 0px 20px;
    }

    .footer-games {
        text-align: left;
    }

    .footer-first-row > div {
        margin-bottom: 30px;
    }

    .footer-second-row {
        padding-top: 0px;
    }

    .provider-slider {
        padding: 0px 30px;
    }

        .provider-slider .slick-next {
            right: -10px;
        }

        .provider-slider .slick-prev {
            left: -10px;
        }

    .download-category-slider {
        padding: 0px 30px;
    }

        .download-category-slider .slick-next {
            right: -10px;
        }

        .download-category-slider .slick-prev {
            left: -10px;
        }

    .settings-dropdown a {
        font-size: 9px;
    }

    #header {
        /* background: #001a3d8a; */
        /*background: #001a3d8a url(../img/Web_Chritmas.png) no-repeat;*/
        /* background-position: center; */
        background-position: top;
        /* background-size: 100% 100%; */
        background-size: 100%;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .settings-dropdown ul {
        column-gap: 20px;
    }

    #side-menu a {
        font-size: 12px;
    }

    #side-menu .first {
        width: 35px;
    }

    .notification-count {
        font-size: 9px;
        padding: 1px 3px 2px 2px;
    }

    .side-menu-profile .first {
        width: 40px;
    }

    .side-menu-profile h4 {
        font-size: 12px;
    }

    .font-14 {
        font-size: 12px;
    }

    .font-15 {
        font-size: 13px;
    }

    section .small-btn {
        font-size: 9px;
    }

    #reward-history-box .small-btn {
        font-size: 12px;
        min-width: 90px;
    }

    #reward-box .small-btn {
        font-size: 12px;
        min-width: 90px;
    }

    .th-search-btn img {
        margin-left: 7px;
    }

    .reward-search-divider {
        display: block;
    }

    #reward-history-box .reward-search-divider > div {
        width: 100%;
    }

    #reward-box .reward-search-divider > div {
        width: 100%;
    }

    .reward-search-divider > .first {
        margin-bottom: 10px;
    }

    .deposit-and-claim-box .small-btn {
        min-width: 55px;
    }

    .deposit-and-claim-box .second {
        justify-content: unset;
        column-gap: 6px;
    }

    .pending-transaction-time {
        font-size: 25px;
    }

    .pending-transaction-box .first h4 {
        margin-bottom: 15px !important;
    }

    .pending-transaction-box .pt-money span {
        font-size: 15px;
    }

    .pending-transaction-box .pt-money {
        font-size: 25px;
    }

    .pending-transaction-box .first {
        width: 160px;
    }

    .font-20 {
        font-size: 17px;
    }

    #downline-search-box-main .template-box {
        padding: 10px 10px;
    }

    .wallet-main-boxes h3 {
        font-size: 14px;
    }

    .font-18 {
        font-size: 15px;
    }

    .font-11 {
        font-size: 10px;
    }

    .font-12 {
        font-size: 11px;
    }

    .reward-items > div:last-child {
        max-width: 90px;
    }

    .hsearch-input {
        width: 75px;
        height: 25px;
        font-size: 12px;
    }

    .hsearch-btn {
        padding: 1px 9px 0px 9px !important;
        min-width: 35px;
        height: 25px;
    }

    .header-currency {
        padding: 0px 20px 0px 10px;
        margin-left: 5px;
    }

    .header-account {
        padding: 2px 10px;
    }

    #download-banner-section {
        background-size: cover;
        background-position: 60%;
    }

        #download-banner-section h2 {
            font-size: 35px;
        }

    .download-buttons .small-btn {
        font-size: 14px;
        width: 160px;
    }

    .download-header .main-nav li a {
        font-size: 15px;
    }

    .download-bottom-content {
        right: 35px;
        top: 27%;
    }

    .sort-games-section .seven-cols .col {
        width: 16.6666666667% !important;
        *width: 116.6666666667% !important;
    }

    .game-box {
        height: 110px;
    }

    .reportTable td, .reportTable th {
        font-size: 9px !important;
        vertical-align: middle !important;
        line-height: 1.2;
    }

    #referral-edit-section #my-tabs li {
        margin-right: 0px;
    }

    .filter-with-scroll-box .filter-items a {
        font-size: 9px;
        width: 100px;
    }

    .filter-with-scroll-box .filter-items a {
        font-size: 9px;
        width: 100px;
    }

    footer p {
        font-size: 12px;
    }

    .footer-first-row > div {
        padding: 0px 30px !important;
    }

    .footer-payment {
        max-width: 250px;
    }

    footer {
        font-size: 10px;
    }

        footer h1 {
            font-size: 12px;
        }

        footer .copy-right h4 {
            font-size: 11px !important;
        }

    .sport-timer {
        /* bottom: 10px; */
    }

    .clock-box {
        font-size: 16px;
    }

    .clock-title {
        font-size: 12px;
    }

    .sports-group-title {
        column-gap: 10px;
        padding: 0px 7px;
    }

    .sport-timer.sport-timer-homepage {
        /* top: 70%; */
    }
    /* .sports-team-box {
    width: 105px;
  } */
    .sports-finals-img {
        width: 50px;
        padding: 5px 0px;
    }

        .sports-finals-img > div:first-child {
            width: 25px;
        }

    .bracket-left-box .sports-finals-box {
        right: 8%;
    }

    .bracket-right-box .sports-finals-box {
        left: 8%;
    }

    #sports-section2 {
        min-height: 310px;
    }

    .sports-finals-top img {
        height: 20px;
    }
}

@media (max-width: 895px) {
    /* .sports-team-box {
        margin: 10px;
    } */
}

@media (max-width: 850px) {
    #register {
        height: 65vh;
    }

    .login-popup-modal .modal-dialog {
        max-width: 700px;
        max-height: 445px;
        margin: auto;
    }

    .register-bottom-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        bottom: -170px;
        right: -260px;
    }

    .sort-game-image {
        height: 100px;
    }

    .logo-image-size {
        height: 100px;
        width: 100px;
    }

    .game-box {
        height: 90px;
    }

    .deposit-divider {
        /* width: 33%; */
        width: 50%;
        padding: 0px 16px;
        border-right: 1px solid #000;
    }

    .download-bottom-content {
        right: 35px;
        top: 30%;
    }

        .download-bottom-content h3 {
            font-size: 15px;
            margin-bottom: 20px;
        }

        .download-bottom-content p {
            font-size: 9px;
        }

    .download-btn-white .small-btn {
        font-size: 12px;
        width: 120px;
        column-gap: 10px;
    }

    .download-btn-white img {
        width: 12px;
    }

    .download-bottom-content .download-buttons {
        margin-top: 15px;
    }

    #sports-section2 {
        min-height: 270px;
    }
}

@media (max-width: 797px) {
    .register-bottom-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        bottom: -170px;
        right: -260px;
    }

    /* .main-nav .mobile-header {
    margin-right: 0px;
    margin-bottom: 3px;
  } */

    .date-lang-box {
        margin-bottom: 10px !important;
    }

    .hsearch-input {
        width: 100px;
    }

    .login-dropdown-btn {
        margin-left: 12px;
    }

    .sign-register-box {
        grid-gap: 10px;
        gap: 10px;
    }

    .main-nav .sign-register-box a {
        font-size: 11px;
        width: 75px;
    }

    .sports-team-box .sport-team-id {
        font-size: 8px;
    }

    .sports-team-box .sport-team-flag {
        width: 15px;
    }
    /* .sports-team-box{
    column-gap: 3px;
    width: 99px;
    height: 40px;
  } */
    .sports-finals-symbol {
        width: 30px;
        font-size: 10px;
        line-height: 1;
    }
    /* .sports-finals-symbol>div{
    margin-top: 10px !important;
  } */
}

@media (max-width: 767px) {
    h6 {
        font-size: 8px;
    }

    h2 {
        font-size: 20px;
    }

    .navbar-light .navbar-toggler {
        /*float: right;*/
        color: #fff;
        border-color: #fff;
        margin-bottom: 10px;
        display: none;
    }

    .mini-nav-btn {
        text-align: right;
    }

    .menu .collapse:not(.show) {
        display: block !important;
    }
    /* .main-nav {
    position: absolute;
  } */
    #download-banner-section {
        background: url(../img/download/bg-mobile.png);
        background-size: cover;
        background-position: right;
        text-align: center;
        padding: 8em 0px 0px;
        margin-bottom: 7em;
    }

    .download-buttons {
        display: block;
    }

        .download-buttons > div {
            margin-bottom: 20px;
        }

        .download-buttons .small-btn {
            margin: auto;
        }

    .download-banner-image {
        display: inline-block;
        margin-bottom: -75px;
    }

    .download-header-box .main-nav {
        position: relative;
    }

    .download-header-box .navbar {
        position: unset;
        padding: 0px 20px;
    }

    .download-header .col-12 {
        position: unset;
    }

    .download-header-box .navbar-collapse {
        background: rgb(255 255 255 / 20%);
        position: absolute;
        height: 100vh;
        right: 0px;
        top: 0px;
        z-index: 2;
        width: 0px;
        backdrop-filter: blur(5px);
        border-radius: 20px 0px 0px 20px;
    }

        .download-header-box .navbar-collapse.show {
            width: 180px;
            transition: 0.5s;
        }

    .download-header .main-nav li {
        display: block;
        margin-bottom: 30px;
        margin-right: 0px;
    }

        .download-header .main-nav li a {
            font-size: 12px;
        }

    .show-mobile {
        display: block;
    }

    .show-desktop {
        display: none;
    }

    .menu-mobile-close {
        text-align: left;
    }

    .download-header .download-register-btn {
        border: 0px;
        border-bottom: 2px solid #ffc159;
        border-radius: 0px;
        display: inline-block;
        padding: 0 0 4px;
    }

    .download-menu-icon {
        display: flex;
        justify-content: end;
        align-items: center;
    }

    .download-buttons .small-btn img {
        max-width: 17px;
    }

    .download-buttons .small-btn {
        font-size: 12px;
        width: 140px;
    }

    .download-header-box .navbar .download-buttons {
        margin-top: 0px;
    }

    .download-header .main-nav li:last-child {
        margin-bottom: 0px;
    }

    .navbar-light .navbar-toggler {
        margin-bottom: 0px;
        margin-top: 2px;
        line-height: 0;
    }

    .download-bottom-content {
        right: 25px;
        top: 50%;
        transform: translate(0, -50%);
    }

    .download-btn-white .small-btn {
        margin: 0px;
    }

    .register-bottom-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        bottom: -170px;
        right: -260px;
    }

    .pin-wrapper {
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    .help-centre-first-list {
        width: 47% !important;
    }

    .sub-sub-list-template-box {
        width: 75%;
    }

    .loading-overlay {
        width: 100% !important;
        height: 100% !important;
    }
}

@media (max-width: 500px) {
    #download-banner-section h2 {
        font-size: 30px;
    }

    #download-banner-section .font-18 {
        font-size: 14px;
    }

    .download-category-item {
        padding: 0px 5px;
    }

    .download-category-slider .slick-arrow:before {
        width: 25px;
        height: 26px;
        background-size: 6px 10px;
        background-position: 9px;
    }

    .download-category-slider .slick-prev {
        left: -23px;
    }

    .download-category-slider .slick-next {
        right: -23px;
    }

    .download-category-item p {
        font-size: 10px;
        margin-top: 8px !important;
    }

    .section-padding {
        padding: 2em 0px;
    }

    .download-main-title:after {
        margin-bottom: 30px;
    }

    .game-box .tag {
        width: 30px;
    }

    #download-feature-section img {
        max-width: 100px;
    }

    #download-feature-section .font-18 {
        font-size: 12px;
    }

    #download-feature-section .mb-5 {
        margin-bottom: 20px !important;
    }

    footer h1 {
        font-size: 12px;
    }

    .download-bottom-content h3 {
        font-size: 9px;
        margin-bottom: 10px;
    }

    .download-bottom-content p {
        font-size: 6px;
    }

    .download-btn-white .small-btn {
        font-size: 7px;
        width: 85px;
        min-width: unset;
        padding: 3px 0px;
        max-width: unset;
    }

        .download-btn-white .small-btn img {
            max-width: 9px;
        }

    .download-btn-white > div {
        margin-bottom: 12px;
    }

    .footer-games div {
        display: block;
    }
}

/*///end media///*/


/* ///game category media query// */
@media (max-width: 1400px) {
    .lottery-red {
        width: 130px;
    }
}

@media (max-width: 1370px) {
    .rng-chip1 {
        top: 15.8%;
        left: 40.2%;
    }
}

@media (max-width: 1300px) {
    .esport-joker {
        max-width: 265px;
    }

    .esport-guy {
        max-width: 265px;
        bottom: 20px;
        top: unset;
    }

    .esport-bird {
        max-width: 150px;
    }

    .esport-text {
        max-width: 300px;
    }

    .rng-text {
        width: 340px;
    }

    .rng-dice1 {
        width: 170px;
    }

    .rng-dice2 {
        width: 90px;
    }

    .rng-chip1 {
        left: 37.2%;
        width: 180px;
    }

    .rng-robot {
        top: 13%;
        right: 4%;
        width: 430px;
    }

    .rng-ball {
        top: 5%;
        right: 5%;
        width: 125px;
    }

    .rng-chip2 {
        right: 2%;
        bottom: -40px;
        width: 275px;
    }

    .rng-girl {
        width: 300px;
    }

    .lottery-card {
        width: 105px;
    }

    .lottery-dice {
        width: 75px;
    }

    .lottery-gold {
        width: 120px;
    }

    .lottery-red {
        width: 110px;
    }

    .lottery-green {
        width: 100px;
    }

    .casino-card {
        width: 80px;
    }

    .casino-heart {
        width: 185px;
    }

    .casino-flower {
        width: 70px;
    }

    .casino-diamond {
        width: 95px;
    }

    .casino-spade {
        width: 105px;
    }
}

@media (max-width: 1200px) {
    .poker-center {
        max-width: 270px;
    }

    .poker-green {
        width: 90px;
    }

    .poker-lavender {
        width: 80px;
    }

    .poker-red {
        width: 60px;
    }
}

@media (max-width: 1100px) {
    .esport-joker {
        max-width: 230px;
    }

    .esport-guy {
        max-width: 230px;
    }

    .esport-bird {
        max-width: 135px;
    }

    .rng-text {
        width: 270px;
    }

    .rng-dice1 {
        width: 130px;
    }

    .rng-dice2 {
        width: 80px;
    }

    .rng-chip1 {
        left: 35.2%;
        width: 160px;
    }

    .rng-robot {
        top: 16%;
        width: 355px;
    }

    .rng-ball {
        top: 7%;
        right: 5.3%;
        width: 110px;
    }

    .rng-chip2 {
        bottom: -25px;
        width: 235px;
    }

    .rng-girl {
        width: 245px;
    }

    .lottery-card {
        width: 95px;
    }

    .lottery-dice {
        width: 65px;
    }

    .lottery-gold {
        width: 110px;
    }

    .lottery-red {
        width: 100px;
    }

    .lottery-green {
        width: 90px;
    }

    .casino-card {
        width: 70px;
    }

    .casino-heart {
        width: 165px;
    }

    .casino-flower {
        width: 60px;
    }

    .casino-diamond {
        width: 85px;
    }

    .casino-spade {
        width: 95px;
    }
}

@media (max-width: 991px) {
    #Esport-section2 {
        height: 550px;
    }
}

@media (max-width: 950px) {
    .esport-joker {
        max-width: 200px;
    }

    .esport-guy {
        max-width: 200px;
    }

    .esport-bird {
        max-width: 120px;
    }

    .esport-text {
        max-width: 250px;
    }

    .rng-text {
        width: 220px;
        left: 9%;
    }

    .rng-dice1 {
        width: 110px;
    }

    .rng-dice2 {
        width: 70px;
        left: 3%;
    }

    .rng-chip1 {
        left: 28.2%;
        width: 140px;
    }

    .rng-robot {
        top: 10%;
        width: 325px;
    }

    .rng-ball {
        top: 2%;
        right: 6%;
        width: 95px;
    }

    .rng-chip2 {
        bottom: -25px;
        width: 185px;
    }

    .rng-girl {
        width: 195px;
    }

    .lottery-card {
        width: 85px;
    }

    .lottery-dice {
        width: 55px;
    }

    .lottery-gold {
        width: 100px;
    }

    .lottery-red {
        width: 80px;
    }

    .lottery-green {
        width: 80px;
    }

    .poker-center {
        max-width: 230px;
    }

    .casino-card {
        width: 60px;
    }

    .casino-heart {
        width: 135px;
    }

    .casino-flower {
        width: 50px;
    }

    .casino-diamond {
        width: 75px;
    }

    .casino-spade {
        width: 85px;
    }

    #Esport-section2 {
        height: 450px;
    }
}

@media (max-width: 820px) {
    .esport-joker {
        max-width: 165px;
    }

    .esport-guy {
        max-width: 165px;
    }

    .esport-bird {
        max-width: 100px;
    }

    .esport-text {
        max-width: 200px;
    }

    .rng-chip1 {
        left: 30.2%;
        top: 11.8%;
    }

    .rng-robot {
        top: 4%;
    }

    .rng-ball {
        top: -5%;
    }

    .lottery-card {
        width: 75px;
    }

    .lottery-dice {
        width: 45px;
    }

    .lottery-gold {
        width: 90px;
    }

    .lottery-red {
        width: 70px;
    }

    .lottery-green {
        width: 60px;
    }

    .poker-green {
        width: 80px;
    }

    .poker-lavender {
        width: 70px;
    }

    .poker-red {
        width: 50px;
    }

    .casino-card {
        width: 50px;
    }

    .casino-heart {
        width: 115px;
    }

    .casino-flower {
        width: 40px;
    }

    .casino-diamond {
        width: 65px;
    }

    .casino-spade {
        width: 75px;
    }

    #Esport-section2 {
        height: 400px;
    }

    .single-item {
        width: 300px !important;
    }
}
/* end game category media */


/* PUWA */

.spinner-border {
    border-color: white;
    border-right-color: transparent;
}

#tr .thr-titles {
    width: 0.1px;
}

.thr-item-fromWallet,
.thr-item-toWallet,
.thr-item-bonus,
.thr-item-fromWalletBalanceBefore,
.thr-item-fromWalletBalanceAfter,
.thr-item-toWalletBalanceBefore,
.thr-item-toWalletBalanceAfter {
    color: #ffffff;
    width: 0.1px;
}

#tr .thr-item-date {
    width: 3.5rem;
}

#dr td, #wr td, #tr td {
    border-bottom: 1px solid #ffffff;
    padding: 5px 0;
}

.commission-wrapper {
    background-color: #02214a;
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 0.75rem;
}

.com-wrapper {
    background-color: #001a3d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2rem;
    border-radius: 0.75rem;
}

.commission-wrapper .claim-btn {
    background: linear-gradient( 180deg, rgb(152 255 203) 0%, rgba(71, 252, 135, 1) 56%, rgba(0, 250, 75, 1) 100% );
    padding: 0.25rem 2rem;
    border-radius: 1rem;
    border: none;
    font-family: averta-semibold;
    font-size: 0.75rem;
}

.comm-summary-table-wrapper {
    background-color: #02214a;
    border-radius: 0.75rem;
}

.table-kpi-summary.comm-summary-table th {
    border-bottom: 1px solid #000000;
}

.table-kpi-summary.comm-summary-table td {
    border-top: none;
}

.badge-no {
    background: linear-gradient(180deg, #f39eb1, #fd3f5e);
}
/* END PUWA */

/* JUNWEI */

.loader {
    border: 5px solid #f3f3f3; /* Light grey */
    border-top: 5px solid #082c6c; /* Dark blue */
    border-radius: 150%;
    animation: spinloader 2s linear infinite;
    padding: 20px;
}

    .loader img {
        height: 50px;
        width: 50px;
        animation: spinlogo 2s linear infinite;
    }

@keyframes spinloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes spinlogo {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.loader-font {
    font-family: Franklin Gothic Medium;
    padding-top: 7px;
    font-size: 17px;
}

.main-wallet-card {
    position: absolute;
    background-color: #001a3d;
    z-index: 1;
    border-radius: 10px;
    /* width: 43%; */
    color: #FFFFFF;
}

.walltet-info-divider {
    /* width: 33%; */
    width: 20%;
    border-top: 0.5px solid #084597;
    margin: 0px;
    bottom: 4px;
    position: relative;
}

.tac_send {
    position: relative;
    text-align: center;
    bottom: 23px;
}

    .tac_send span {
        color: #FFFFFF;
        font-family: averta-semibold;
        font-size: 9px;
    }

.tac_send_again {
    position: relative;
    text-align: center;
    top: 7px;
}

    .tac_send_again button {
        color: #FFFFFF;
        font-family: averta-semibold;
        font-size: 9px;
        border: none;
        background: none;
    }

.reset_password_success {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-items: center;
    justify-content: center;
    position: relative;
    bottom: 45px;
}

    .reset_password_success span {
        width: 100%;
        padding-top: 20px;
        font-family: averta-semibold;
        color: white;
        position: relative
    }

.forgot-password-back-btn {
    width: 19px;
    cursor: pointer;
}

/* END JUNWEI */

td.rdtDay.rdtDisabled {
    color: grey !important;
    text-decoration: line-through;
}

.rdt .rdtPicker .rdtDays {
    min-width: 200px !important
}

.rdt .rdtPicker {
    font-size: 14px;
}

.masterProductContent {
    color: white;
    padding: 14px 18px;
    margin: 0px 27px 20px 12px;
    background-color: #032451;
    border-radius: 16px;
    word-wrap: break-word;
    line-height: 2rem;
}

.reward-card {
    background-color: #02214a;
    display: block;
    align-items: center;
    padding: 15px 30px;
    margin-top: 20px;
    width: 250px;
    border-radius: 8px;
}

.reward-divider {
    border-top: 1px solid #bbb;
    margin-top: 35px;
}

reward-span {
    margin-left: 10px;
}

reward-up-down-button {
    position: absolute;
    left: 0px
}

.reward-card .first .text-white .reward-button:hover .tooltip-text2,
.header-wallet-balance:hover .tooltip-text {
    visibility: visible !important;
}

.reward-info-card {
    margin-top: 165px;
    margin-left: -30px;
}

.reward-card .first .img2 {
    display: none;
}

.reward-card .first[aria-expanded="true"] .img1 {
    display: none;
}

.reward-card .first[aria-expanded="true"] .img2 {
    display: inline-block;
}

.customFontMontserratsansserif {
    font-family: "Montserrat",sans serif;
}

.modal-header::before {
    content: '';
    position: absolute;
    height: 1px;
    width: 37px;
    background: #001a3d;
    bottom: -1px;
    left: 0;
    z-index: 1111;
}

.modal-header::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 37px;
    background: #001a3d;
    bottom: -1px;
    right: 0;
    z-index: 1111;
}

.modal-header {
    position: relative
}


/* ///cny video css - john barcove/// */
.cny-pop-trigger {
    position: fixed;
    right: 0;
    z-index: 999;
    width: 230px;
    top: 205px;
    cursor: pointer;
}

.move-negative-right {
    right: -10px;
}

.cny-trigger-close {
    position: absolute;
    top: 100px;
    right: 18px;
    width: 15px;
    cursor: pointer;
}

    .cny-trigger-close img {
        width: 100%;
    }

.cny-video {
    position: fixed;
    z-index: 999999;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000c4;
    backdrop-filter: blur(2px);
}

.cny-video-box {
    width: 80%;
    max-width: 800px;
    position: relative;
    margin: auto;
}

.cny-video video {
    border: 2px solid #FFC159;
    border-radius: 10px;
}

.cny-video-close {
    position: absolute;
    top: -15px;
    right: -15px;
    width: 28px;
    cursor: pointer;
}

    .cny-video-close img {
        width: 100%;
    }

.connect-font {
    font-family: MuseoModerno-Bold;
}

/* Mini Games */
.mini-games {
    position: fixed;
    background-image: url("../../assets/img/mini-games/MiniGamesIcon.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 187px;
    height: 106px;
    bottom: 10%;
    right: 5%;
}

.mini-games-modal.isVisible {
    opacity: 1;
    visibility: visible;
    transition-delay: 0.3;
}

.mini-games-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 2rem;
    background-color: rgba(0,0,0,0.5);
    display: flex;
}

.mini-games-dialog {
    height: auto;
    position: absolute;
    right: 3%;
    bottom: 23%;
}

.mini-games-modal.isVisible .mini-games-dialog {
    opacity: 1;
    transition-delay: 0.2s;
}

.mini-games-header {
    position: relative;
    display: flex;
    justify-content: center;
}

    .mini-games-header img {
        height: 180px
    }

.mini-close-button {
    background-image: url("../../assets/img/mini-games/IconClose.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: transparent;
    width: 24px;
    height: 24px;
    background-color: transparent;
    border: none;
    position: absolute;
    right: 1%;
    top: 50%;
    cursor: pointer;
}

.mini-games-body {
    margin-top: 10px;
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background-color: #62F0FF;
    position: relative;
}

.mini-games-tab {
    display: flex;
    list-style: none;
    justify-content: space-between;
    padding: 10px 10px 0;
    margin: 0;
}

.mini-games-items {
    padding: 8px 10px 8px;
    border-bottom: none !important;
    cursor: pointer;
}

    .mini-games-items.selected {
        background-image: linear-gradient(#1275E2, #003180);
        border-top-left-radius: 50%;
        border-top-right-radius: 50%;
    }

    .mini-games-items img {
        width: 40px;
        height: 40px;
    }

.mini-games-section {
    background-color: #003180 !important;
    padding: 5px 0 !important;
    width: 248px;
    height: 237px;
    position: relative;
    margin-bottom: 0 !important;
    border-radius: 0 0 0 0 !important;
}

.mini-games-content {
    height: 227px;
    position: absolute;
}

    .mini-games-content.show {
        visibility: visible;
        opacity: 1;
    }

.iframe {
    border: none;
}

/* 
.mini-games-content{
    display: block !important;
} */

@media only screen and (max-width: 980px) {
    .mini-games {
        height: 60px;
        width: 104px;
        bottom: 5%;
    }

    .mini-games-modal {
        justify-content: center;
        align-items: center;
    }

    .mini-games-dialog {
        position: unset;
    }
}

.loading {
    /* Absolute position */
    left: 0;
    position: absolute;
    top: 0;
    /* Take full size */
    height: 100%;
    width: 100%;
    /* Center */
    align-items: center;
    display: flex;
    justify-content: center;
    color: white;
}

.more-game-img {
    position: absolute;
    top: 69px;
    right: 0;
    margin-right: -39px;
    cursor: pointer;
}

/* Mini Games END */


.deposit-form {
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
}

.deposit-table .border-top {
    border-top-width: 0px !important;
}

.deposit-table .border-bottom {
    border-bottom-width: 0px !important;
}

.bank-text {
    /*  color: #002E6C;*/
    color: #ffffffc0;
}

.float-right-icon {
    float: right;
}

.deposit-table .custom-checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

    .deposit-table .custom-checkbox input[type="radio"] {
        /* remove standard background appearance */
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        /* create custom radiobutton appearance */
        display: inline-block;
        width: 18px;
        height: 18px;
        /* background-color only for content */
        background-clip: content-box;
        /*    border: 2px solid #012C65;*/
        border: 2px solid #ffffff;
        border-radius: 50%;
    }

        /* appearance for checked radiobutton */
        .deposit-table .custom-checkbox input[type="radio"]:checked {
            /*      background-color: #012C65;*/
            background-color: #ffffff;
            border: none;
        }

.deposit-table {
    border-spacing: 0;
    border-collapse: separate;
    border: none !important;
    font-weight: bold;
}

    .deposit-table td {
        padding-left: 20px !important;
        padding-right: 20px !important;
        /* border: 1px solid rgba(255, 255, 255, 0.3); */
        border-top: 0px;
    }


        .deposit-table td:nth-child(n+1) {
            /* border-bottom-width: 0px !important; */
            background-color: #001A3D;
        }

    .deposit-table .first-part {
        padding-top: 20px !important;
    }

    .deposit-table .third-part {
        border-bottom: 1px solid #000 !important;
        padding-bottom: 20px !important;
    }

    .deposit-table tr:last-child td {
        border-bottom: 0px !important;
    }

    .deposit-table tr:nth-last-child(1) td:nth-last-child(1), tr:nth-last-child(1) td:first-child {
        border-bottom-width: 1px !important;
    }

    .deposit-table tr:last-child td:last-child {
        border-bottom-right-radius: 10px;
    }

    .deposit-table tr:nth-last-child(2) td:first-child {
        border-bottom-left-radius: 10px;
        border-bottom: unset !important;
    }

    .deposit-table tr:nth-last-child(2) td:nth-child(2) {
        border-bottom: unset !important;
    }

    .deposit-table tr:first-child td:first-child {
        border-top-left-radius: 10px;
    }

    .deposit-table tr:nth-child(n+1) td:first-child {
        /* border-right-width: 0px !important; */
        background-color: #001A3D;
    }

    .deposit-table tr:first-child td:last-child {
        border-top-right-radius: 10px
    }

    .deposit-table .border-top.border-bottom {
        max-width: 30px;
        word-wrap: break-word;
        border-bottom: 1px solid #000 !important;
        vertical-align: middle;
    }

.bank-text.img-col#img-col-id {
    max-width: 50px;
    word-wrap: break-word;
    border-bottom: 1px solid #000;
    vertical-align: middle;
}

.bank-img {
    width: 70px;
    height: auto;
}

/* MarqueeTagContent */

.marquee-tag-backgroud {
    background-image: url(../../assets/img/icon/Announce_Rectangle_Desktop.png);
    height: 50px;
}

.marquee-tag {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: auto;
}

.marquee-tag-div {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    font-size: 13px;
    color: #fff;
    font-family: "averta-bold";
    padding: 0px 25px;
    height: 30px;
    min-height: 30px;
    text-align: center;
    align-items: center;
    justify-content: space-between;
}

.marquee-tag .text {
    display: block;
    color: #FFFFFF;
    font-weight: 100;
    font-size: 15px;
    white-space: nowrap;
}

.marquee-tag .image {
    width: 25px;
    height: 25px;
}

/* MarqueeTagContent END*/


#loyalty-section .card {
    background-color: #02214a;
}

.loyalty-divider {
    border-top: 1px solid #bbb;
    margin-top: 35px;
}

#loyalty-section .loyalty-disabled:hover {
    cursor: not-allowed !important;
    opacity: 0.65;
}


.loyalty-wrapper {
    background-color: #02214a;
    margin: 1rem 0;
    padding: 1.5rem;
    border-radius: 0.75rem;
}

.loyal-wrapper {
    background-color: #001a3d;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem 2rem;
    border-radius: 0.75rem;
}

.loyalty-wrapper .claim-btn {
    background: linear-gradient( 180deg, rgb(152 255 203) 0%, rgba(71, 252, 135, 1) 56%, rgba(0, 250, 75, 1) 100% );
    padding: 0.25rem 2rem;
    border-radius: 1rem;
    border: none;
    font-family: averta-semibold;
    font-size: 0.75rem;
}

.loyal-summary-table-wrapper {
    background-color: #02214a;
    border-radius: 0.75rem;
}

.label-text {
    color: white;
}

.settings-email-verified-1 {
    position: absolute;
    right: 20px;
    top: 15px;
}

.send-tac-email-btn {
    position: absolute;
    right: 20px;
    top: 40px;
    height: 25px;
    width: fit-content;
    font-size: 12px;
    font-weight: bold;
    color: rgba(255, 193, 89, 1);
    background: transparent;
    text-decoration: underline;
    border: 0;
    border-radius: 10px;
}

    .send-tac-email-btn:disabled {
        opacity: 0.8;
    }

.send-tac-email-btn-1 {
    position: absolute;
    right: 20px;
    top: 10px;
    height: 25px;
    width: fit-content;
    font-size: 12px;
    font-weight: bold;
    color: rgba(255, 193, 89, 1);
    background: transparent;
    text-decoration: underline;
    border: 0;
    border-radius: 10px;
    cursor: pointer;
}

.send-tac-email-btn:disabled {
    opacity: 0.8;
}

/* Bonus Deposit */
.bd-card-panel {
    display: flex;
    background: #fff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    width: inherit;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    padding: 5px 10px;
    margin: 0px 10px 0px 10px;
}

.bonusdeposit-menu .bd-card-panel a {
    text-decoration: none;
}

.bonusdeposit-title {
    font-size: 18px;
}

.bonusdeposit-container {
    font-size: 12px;
}

.bonusdeposit-menu {
    /*background-color: red;*/
    height: fit-content;
    /*overflow: scroll;*/
    /*font-size: 12px;*/
    /*padding-bottom: 20%;*/
    padding-bottom: 5px;
}

    .bonusdeposit-menu .bd-card-panel .bonusdeposit-menu-wrapper {
        display: flex;
        align-items: center;
        padding: 0 20px;
        color: #002e6c;
        height: 10rem;
    }

.accordion-container .accordion-header .accordion-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #F0F8FF;
    /*background-color: #02214a;*/
    border-radius: 10px;
    border: 0;
    width: 100%;
    text-align: left;
    padding-left: 17px;
}

.accordion-container .accordion-header label {
    font-size: 16px;
    color: #0C2756 !important;
}

.accordion-container .accordion-header {
    margin-bottom: 12px;
}

.accordion-container .accordion-body .nav .nav-link {
    margin: 7px 0 14px;
    padding: 0;
}

.accordion-container .accordion-button:after {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: '\f078';
    padding-right: 19px;
}

.accordion-container .accordion-button:not(.collapsed):after {
    content: '\f077';
}

/* Bonus Deposit END */

.lang-toggle {
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-left: 10px;
    align-items: center;
    cursor: pointer;
}

.lang-dropdown {
    position: absolute;
    top: 25px;
    right: 0;
    background: white;
    color: black;
    z-index: 999;
    padding: 15px 15px;
    border-radius: 15px;
    margin-top: 5px;
}

.dropdown-lang {
    white-space: nowrap;
    padding: 0 8px;
    font-size: 10px;
    color: #7C7F80;
    position: relative;
    cursor: pointer;
}

    .dropdown-lang:first-child {
        padding-left: 0px;
    }

    .dropdown-lang:last-child {
        padding-right: 0px;
    }

    .dropdown-lang:after {
        content: ' ';
        height: 7px;
        width: 1px;
        background: #7C7F80;
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);
    }

    .dropdown-lang:last-child:after {
        display: none;
    }

.lang-country-name {
    font-size: 13px;
}

.lang-country-flag img {
    border: 3px white solid;
    border-radius: 15px;
    width: 25px;
    height: 25px;
}

.header-dropdown-box:first-child .dropdown-lang.active {
    color: blue;
    font-weight: bold;
}

.lang-name-short {
    white-space: nowrap;
    padding: 0 5px 0 10px;
}

.header-dropdown-box {
    display: flex;
    align-items: center;
    column-gap: 10px;
    margin-bottom: 20px;
}

    .header-dropdown-box:last-child {
        margin-bottom: 0px;
    }

#deposit-section .accordion-bonusdeposit {
    background-color: #02214a;
    padding: 20px 25px;
    border-radius: 10px;
    margin-bottom: 20px;
}

#deposit-section .accordion-container .accordion-header .accordion-button {
    background: transparent;
    border: 0px;
    padding: 0px;
    color: #fff;
}

#deposit-section .accordion-container .accordion-button:after {
    padding-right: 0px;
    font-size: 15px;
    color: #fff;
    line-height: 1;
}

#deposit-section .accordion-container .accordion-header {
    margin-bottom: 0px;
}

#deposit-section .accordion-body {
    margin-top: 0px;
    padding-bottom: 0px;
}

.deposit-slick-item {
    margin: 7px;
    background: #001A3D 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    padding: 7px;
    border: 1px solid #001A3D;
    height: 400px;
}

    .deposit-slick-item.active {
        border: 3px solid #3369FF;
    }

.deposit-slick {
    margin: 0px -7px;
}

.deposit-bonus-image {
    position: relative;
}

.deposit-slick-item .deposit-bonus-image:after {
    content: ' ';
    width: 12px;
    height: 12px;
    background: #fff;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 100px;
}

.deposit-slick-item.active .deposit-bonus-image:after {
    background: linear-gradient( 180deg, rgba(255, 128, 19, 1) 0%, rgba(255, 84, 29, 1) 56%, rgba(255, 39, 39, 1) 100% );
}

.deposit-bonus-details {
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    padding: 10px 10px 0px;
    line-height: 20px;
    font-family: "averta-semibold";
}

#deposit-section #my-tabs a {
    margin-bottom: 0px;
}

#deposit-section .slick-dots {
    bottom: -35px;
}

.claim-limit-reminder {
    font-size: 8px;
}

.slick-next, .slick-prev {
    top: 48% !important;
    z-index: 2;
}

.react-datepicker-wrapper {
    width: 100% !important;
}

.modal-close-btn {
    color: white;
    padding-right: 15px;
}

    .modal-close-btn span {
        font-size: 30px;
    }

.withdrawal-warning-text img.blue-warning-icon {
    width: 15px;
    height: 15px;
    margin-right: 5px;
    margin-bottom: 3px;
}

.live-chat-box {
    width: 80%;
    position: relative;
    margin: auto;
    text-align: center;
}

.live-chat-img-button {
    width: 75%;
    height: 75%;
}

.live-chat-modal-container {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgb(25 35 47)), linear-gradient(271.33deg, rgb(78 75 200 / 69%) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    padding: 25px 25px;
    border-radius: 8px;
    margin-top: 12px !important;
}

#custom-modal3 {
    align-items: flex-end;
    height: 100vh;
    margin: 0 !important;
    text-align: center;
}

    #custom-modal3 .modal-content {
        background: transparent;
        box-shadow: unset;
        max-height: 90%;
        padding-left: 58%;
        padding-bottom: 1%;
    }

        #custom-modal3 .modal-content::-webkit-scrollbar {
            display: none;
        }

    #custom-modal3 .modal-body {
        padding: 40px 20px 40px;
        max-width: 500px;
        margin: auto;
        background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
        -webkit-backdrop-filter: blur(35px);
        backdrop-filter: blur(35px);
        border-radius: 20px 20px 0px 20px;
        border: 0px;
        width: 100%;
    }

.live-chat {
    position: fixed;
    z-index: 10;
    left: 94%;
    top: 90%;
    transform: translate(-50%,-50%);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    background: #000000c4;
    backdrop-filter: blur(2px);
    background-color: dodgerblue;
    height: 65px;
    width: 65px;
    border-radius: 50%
}

.modal-bottom-live-chat {
    position: fixed;
    left: 0;
    bottom: 80px;
    max-height: 100%;
    overflow: auto;
    width: 95%;
}

.live-chat-grid {
    display: flex;
    justify-content: space-between;
    background-color: blue;
}

.live-chat-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-items: center;
    grid-row-gap: 20px;
}

.live-chat-item {
    background-color: #f0f0f0;
    padding: 20px;
    text-align: center;
}

.live-chat-gird {
    border-radius: 50%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 50px;
    max-width: 50px;
}

.live-chat-channel-name {
    color: white;
    display: flex;
    justify-items: center;
    align-items: center;
}

.dark-bg-live-chat {
    height: 1000vh;
    width: 100%;
    background: #000000a8;
    backdrop-filter: blur(5px);
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 10
}

.live-chat-button-container {
    position: absolute;
    left: -363px;
    top: -320px;
}

.live-chat-button-description {
    font-size: 10px;
    color: white !important;
    display: flex;
    justify-content: center;
}

.live-chat-icon {
    display: grid;
    justify-items: center;
}

.flex-align-baseline {
    align-items: baseline;
}

.custom-color-yellow {
    color: #FFC159;
}

.instant-bank-list {
    display: grid;
    justify-items: center;
    grid-template-columns: repeat(4,1fr);
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}



.instant-bank {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 100%;
    align-items: center;
    cursor: pointer;
    background: white;
    opacity: 0.7;
    position: relative;
}

    .instant-bank .tick-image {
        position: absolute;
        top: -8px;
        right: -10px;
        width: 20px;
    }

    .instant-bank [type=radio] {
        position: absolute;
        opacity: 0;
        width: 0;
        height: 0;
    }

        .instant-bank [type=radio] + img {
            cursor: pointer;
            width: 100%;
        }

        /* CHECKED STYLES */
        .instant-bank [type=radio]:checked {
            outline: 2px solid #f00;
        }

.instant-bank-list .active {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border: 3px solid #3369FF;
    opacity: 1;
}

.deposit-alert-warning {
    color: white;
    border: #3369FF 1px solid;
    border-radius: 10px;
}

.deposit-custom-radio {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    cursor: pointer;
}

    .deposit-custom-radio input {
        display: none;
    }

.deposit-custom-radio-label {
    line-height: 1;
    color: #898F97;
    font-size: 14px;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

    .deposit-custom-radio-label > div {
        display: flex;
        align-items: center;
        gap: 15px
    }

.deposit-custom-radio .checkmark-border {
    border: 2px solid #898F97;
    border-radius: 100px;
    display: inline-block;
    margin-right: 10px;
    line-height: 0;
}

.deposit-custom-radio .checkmark-logo {
    margin-right: 20px;
    line-height: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
}

.deposit-custom-radio .checkmark-dot {
    border-radius: 100px;
    background: #898F97;
    width: 7px;
    height: 7px;
    display: inline-block;
    margin: 2px;
}

.deposit-custom-radio input:checked ~ .checkmark-border {
    border: 2px solid #3369FF;
}

    .deposit-custom-radio input:checked ~ .checkmark-border .checkmark-dot {
        background: #3369FF;
    }

.deposit-custom-radio input:checked ~ .checkmark-title {
    color: #3369FF;
}

.deposit-custom-radio input:checked ~ div .checkmark-title {
    color: #3369FF;
}

.deposit-custom-radio input:checked ~ div .checkmark-details {
    color: #3369FF;
}


.deposit-list-item {
    margin-top: 15px;
}

.deposit-provider-box {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
}


    .deposit-provider-box > div {
        height: 60px;
        width: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
    }

        .deposit-provider-box > div .tick-image {
            position: absolute;
            top: -8px;
            right: -10px;
            width: 20px;
        }

    .deposit-provider-box > .active {
        border-radius: 8px;
        border: 3px solid #3369FF;
    }

.deposit-custom-hr {
    border: none;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
}

.deposit-provider-image {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.unavailable {
    cursor: not-allowed;
    opacity: 0.3;
    pointer-events: none;
}

#success-modal {
    padding: 0px 20px;
}

    #success-modal .modal-content {
        color: #ffffff;
        border-radius: 30px;
        overflow: hidden;
        background-color: #2c2c2c;
    }

    #success-modal .modal-body {
        /*  background: linear-gradient(0deg, rgba(36, 80, 202, 0.5) 0%, rgb(248 230 255 / 10%) 100%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.15));*/
        background: #04102a;
    }

.modal-success-container {
    text-align: center;
}

    .modal-success-container img {
        margin: auto;
    }

.modal-success-title {
    font-size: 22px;
    font-family: "poppins-semibold";
    color: #FFFFFF;
}

.modal-success-details {
    font-size: 15px;
    font-weight: 500;
    color: #A4A4A4;
}

.btn-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.custom-btn {
    background: #3369FF;
    box-shadow: 0px 5px 30px rgba(51, 105, 255, 0.7);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-family: Montserrat;
}

.justify-space-between {
    justify-content: space-between;
}

.points-detail-header {
    background: #02214a;
    border-radius: 10px;
    padding: 15px 25px;
    margin-bottom: 10px;
    color: white;
}

.small-btn[disabled] {
    background: linear-gradient( 180deg, rgb(181 181 181) 0%, rgb(155 155 155) 56%, rgb(117 117 117) 100% );
    border: none;
    cursor: not-allowed;
}

.game-points-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 15px 15px;
}

    .game-points-container.section1 {
        padding: 10px 15px;
    }

    .game-points-container .title {
        font-size: 12px;
        color: #fff;
        font-family: "averta-semibold";
    }

    .game-points-container .details {
        font-size: 14px;
        color: #fff;
        font-weight: bold !important;
        font-family: "averta-semibold" !important;
    }

    .game-points-container .first {
        display: flex;
        align-items: center;
        column-gap: 15px;
    }

    .game-points-container .amount {
        color: #3369FF;
        font-size: 14px;
        font-weight: bold !important;
        font-family: "averta-semibold" !important;
    }

    .game-points-container .custom-icon-box {
        padding: 8px 5px;
    }

.game-pts-item-card {
    border-radius: 10px;
    /* height: 250px; */
    background-color: #0D1A3B;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    padding: 10px;
}

    .game-pts-item-card .game-pts-item-img {
        border-radius: 10px 10px 0 0;
        width: 100%;
    }

    .game-pts-item-card .game-pts-item-card-body {
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding: 10px 0 0 0;
    }

        .game-pts-item-card .game-pts-item-card-body .game-pts-item-title {
            margin-bottom: 5px;
            color: #FFFFFF;
            font-size: 14px;
            min-height: 30px;
            text-align: left;
            /* font-weight: bold; */
            font-weight: bold !important;
            font-family: "averta-semibold" !important;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .game-pts-item-card .game-pts-item-card-body .game-pts-item-text {
            /* margin-bottom: 5px; */
            color: #3369FF;
            font-size: 12px;
            text-align: left;
            font-weight: bold !important;
            font-family: "averta-semibold" !important;
        }

.gamepoint-voucher-img-box {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.33deg, rgba(78, 75, 200, 0.3) 0%, rgba(254, 94, 171, 0.03) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    padding: 15px;
    height: 250px;
}

    .gamepoint-voucher-img-box > img {
        max-height: 215px;
        object-fit: contain;
    }

.gamepoint-voucher-title {
    color: white;
    font-size: 17px;
    font-weight: bold !important;
    font-family: "averta-semibold" !important;
}

.gamepoint-voucher-point-box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    font-size: 14px;
    font-family: "averta-semibold" !important;
}

    .gamepoint-voucher-point-box .first {
        color: #898F97;
    }

    .gamepoint-voucher-point-box .second {
        color: #3369FF;
    }

.hr-middle {
    border-top: 2px solid #898F97;
    width: 100%;
    height: 1px;
}

.hr-left-corner {
    /*border-top: 2px solid #3369FF;*/
    border-top: 2px solid #898F97;
    width: 20%;
    height: 1px;
}

.content-container {
    color: white;
}

.register-steps-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

    .register-steps-container .step-separator-gray {
        width: 50px;
        height: 2px;
        background: #898F97;
        border-radius: 100px;
        margin-bottom: 5px;
        margin-bottom: 20px;
    }

    .register-steps-container .step-separator-blue {
        width: 50px;
        height: 2px;
        background: #3369FF;
        border-radius: 100px;
        margin-bottom: 5px;
        margin-bottom: 20px;
    }

.register-header {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

    .register-header .first {
        font-size: 32px;
        font-weight: bold;
    }

.or-box {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-size: 13px;
    color: #898F97;
}

    .or-box > div {
        white-space: nowrap;
    }

    .or-box .or-line {
        background: #898F97;
        width: 100%;
        height: 1px;
        border-radius: 100px;
    }

.custom-input-group {
    position: relative;
}

    .custom-input-group input {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.6), rgba(25, 35, 47, 0.6)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%);
        box-shadow: 0px 4px 7px 0px #0000004D;
        height: 50px;
        width: 100%;
        border: 0px;
        border-radius: 8px;
        padding-left: 70px;
        padding-right: 10px;
        color: #fff;
    }

    .custom-input-group.normal input {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.6), rgba(25, 35, 47, 0.6)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%);
        box-shadow: 0px 4px 7px 0px #0000004D;
        height: 50px;
        width: 100%;
        border: 0px;
        border-radius: 8px;
        padding-left: 25px;
        color: #fff;
        padding-right: 25px;
    }

    .custom-input-group .left-img {
        position: absolute;
        top: 50%;
        left: 25px;
        transform: translate(0, -50%);
    }

    .custom-input-group .right-img {
        position: absolute;
        top: 50%;
        right: 25px;
        transform: translate(0, -50%);
    }

    .custom-input-group input[type="password"]::-ms-reveal,
    .custom-input-group input[type="password"]::-ms-clear{
        display:none;
    }

    .register-form-box {
        max-width: 500px;
        margin: auto;
    }

.custom-blue-btn {
    background: #3369FF;
    box-shadow: 0px 5px 30px 0px #3369FFB2;
    color: #fff;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    font-size: 16px;
}

    .custom-blue-btn:hover {
        background: #3369FF;
        box-shadow: 0px 5px 30px 0px #3369FFB2;
        color: #fff;
        width: 100%;
        height: 50px;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        opacity: .8;
    }

.register-mini-text {
    color: #898F97;
    text-align: center;
    font-size: 12px;
}

.color-mainBlue {
    color: #3369FF;
}

.color-gray2 {
    color: #898F97;
}

.color-gray {
    color: #A4A4A4;
}

.custom-phone-group select {
    background: transparent;
    border: 0px;
    height: 50px;
    color: #A4A4A4;
    position: absolute;
    top: 50%;
    left: 25px;
    transform: translate(0, -50%);
}

.custom-phone-group {
    position: relative;
}

    .custom-phone-group input {
        background: linear-gradient(0deg, rgba(25, 35, 47, 0.6), rgba(25, 35, 47, 0.6)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%);
        box-shadow: 0px 4px 7px 0px #0000004D;
        height: 50px;
        width: 100%;
        border: 0px;
        border-radius: 8px;
        padding-left: 90px;
        padding-right: 10px;
    }

.line-btn {
    display: flex;
    align-items: center;
    column-gap: 8px;
    justify-content: center;
    font-size: 12px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.5), rgba(25, 35, 47, 0.5)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%);
    border-radius: 8px;
    height: 45px;
    position: relative;
    color: #fff;
}

    .line-btn:hover {
        color: #fff;
        opacity: .8;
    }

.line-box {
    border: 1px solid #3369FF;
    padding: 5px;
    border-radius: 8px;
}

#register-section .custom-checkbox input {
    display: none;
}

#register-section .custom-checkbox span {
    border: 2px solid transparent;
    border-radius: 1px;
    background: #A4A4A499;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    display: inline-block;
    border-radius: 5px;
}

#register-section .custom-checkbox input:checked ~ span {
    background-color: #48b32dcc;
    background-image: url("../../assets/img/v3/check.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

#register-section .custom-checkbox {
    font-size: 12px;
    margin-bottom: 6px;
    padding-left: 0;
    color: white;
    align-items: center;
    grid-column-gap: 15px;
    -webkit-column-gap: 15px;
    column-gap: 15px;
    display: flex;
    font-weight: 400;
}

    #register-section .custom-checkbox .checkmark {
        cursor: pointer;
    }

.login-password-wrapper {
    position: relative;
}

    .login-password-wrapper .right-img {
        position: absolute;
        top: 0px;
        right: 0;
        padding: 10px 20px;
    }

.resend-tac {
    cursor: pointer;
}

    .resend-tac:hover {
        color: #3369ff;
        text-decoration: underline;
        font-weight: bold;
    }

#cockfighting-section2 {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 50vh;
}

    #cockfighting-section2 .cock-fighting-icon {
        cursor: pointer;
    }

/* /////////john new//////// */
#deposit-section .accordion-bonusdeposit {
    padding: 0px;
    background: transparent;
    margin: 0px;
    border-radius: 0px;
}

    #deposit-section .accordion-bonusdeposit .accordion-header {
        border-bottom: 1px solid #D9D9D91A;
        /* margin-bottom: 15px; */
        padding: 15px;
    }

#deposit-section .accordion-button .accordion-circle-icon {
    background: transparent;
    position: relative;
    border: 2px solid #898F97;
    width: 18px;
    height: 18px;
    border-radius: 100px;
}

    #deposit-section .accordion-button .accordion-circle-icon:after {
        content: ' ';
        background: #898F97;
        position: absolute;
        width: 9px;
        height: 9px;
        left: 51%;
        top: 50%;
        transform: translate(-50%,-50%);
        /* z-index: -1; */
        border-radius: 100px;
    }

#deposit-section .accordion-container .accordion-header .accordion-button {
    color: #898F97;
    font-family: Montserrat;
}

#deposit-section .accordion-container .accordion-button:after {
    color: #898F97;
}

#deposit-section .accordion-container .accordion-header .accordion-button[aria-expanded="true"] {
    color: #3369FF;
}

#deposit-section .accordion-container .accordion-button[aria-expanded="true"]:after {
    color: #3369FF;
}

#deposit-section .accordion-button[aria-expanded="true"] .accordion-circle-icon {
    background: transparent;
    position: relative;
    border: 2px solid #3369FF;
    width: 18px;
    height: 18px;
    border-radius: 100px;
}

    #deposit-section .accordion-button[aria-expanded="true"] .accordion-circle-icon:after {
        content: ' ';
        background: #3369FF;
        position: absolute;
        width: 9px;
        height: 9px;
        left: 51%;
        top: 50%;
        transform: translate(-50%,-50%);
        /* z-index: -1; */
        border-radius: 100px;
    }

#deposit-section .accordion-button > div {
    display: flex;
    align-items: center;
    column-gap: 15px;
}

#deposit-section .accordion-body {
    border-bottom: 1px solid #D9D9D91A;
    padding-bottom: 20px;
}

#deposit-section .deposit-container-box {
    background-color: #02214a;
    padding: 20px 25px;
    border-radius: 10px;
    margin-bottom: 20px;
    min-height: 535px;
}

.deposit-normal-bank-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-items: center;
    grid-gap: 20px;
    max-width: 700px;
    margin: auto;
    border-left: 2px solid #898F97;
    padding-left: 20px;
}

    .deposit-normal-bank-list-container > div {
        width: 100%;
        background: #D2DEFF;
        padding: 5px;
        align-items: center;
        justify-content: center;
        display: flex;
        height: 60px;
        border-radius: 8px;
        cursor: pointer;
    }

        .deposit-normal-bank-list-container > div.active {
            border: 2px solid #3369FF;
            background: #ABC1FF;
        }

.deposit-normal-bank-details-container {
    padding: 20px;
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border-radius: 8px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
}

.deposit-normal-bank-details-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

    .deposit-normal-bank-details-item .second {
        display: flex;
        align-items: center;
        column-gap: 10px;
    }

        .deposit-normal-bank-details-item .second .copy {
            width: 20px;
            height: 20px;
            border-radius: 100px;
            background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
            align-items: center;
            display: flex;
            justify-content: center;
            box-shadow: 0px 4px 7px 0px #0000000D;
            cursor: pointer;
        }

#deposit-section .accordion-item:last-child .accordion-body {
    border-bottom: 0px;
    padding-bottom: 0px;
}
.amount-row{
    display: grid;
    grid-template-columns: repeat( auto-fit, minmax(16%, 1fr) );
    justify-items: center;
    grid-gap: 20px;
}
.amount-row button{
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)),
    linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border: 0px;
    color: #898F97;
    width: 100%;
    font-size: 14px;
    line-height: 1;
    padding: 14px 5px;
}
.amount-row button:hover{
    opacity: .8;
    border: 0px;
    color: #898F97;
}
.clear-amount{
    top: 50%;
    transform: translate(0,-50%);
    right: 20px;
    position: absolute;
    cursor: pointer;
}

.normal-bank-item-container {
    display: flex;
    align-items: center;
}

.normal-parent-bank-title {
    min-width: 170px;
    max-width: 170px;
    width: 170px;
    text-align: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.normal-bank-item-container .deposit-normal-bank-list-container {
    width: 100%;
}

.custom-hr {
    background: #D9D9D91A;
}

.normal-deposit-remider {
    padding: 15px;
    background: #311e5840;
    border: 1px solid #3369FFCC;
    border-radius: 8px;
}

    .normal-deposit-remider .title {
        color: #3369FF;
    }

    .normal-deposit-remider .details {
        font-size: 12px;
        color: #A4A4A4;
    }

        .normal-deposit-remider .details ol {
            padding-left: 15px;
        }

.normal-bank-selected-img {
  background: #D2DEFF;
  padding: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 60px;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  border: 3px solid #FFA500;
  position: relative;
}

.normal-bank-selected-img > div > img {
  height: 20px;
  width: 20px;
}

    .normal-bank-selected-img > img {
        width: 100%;
    }

.normal-bank-per-section-box {
    display: flex;
    align-items: center;
}

    .normal-bank-per-section-box > .first {
        width: 170px;
        text-align: center;
        color: white;
        padding: 0 10px;
        font-weight: bold;
    }

    .normal-bank-per-section-box > .second {
        width: 100%;
        border-left: 2px solid #898F97;
        padding-left: 20px;
    }

.normal-bank-tick-selected {
    position: absolute;
    top: -10px;
    right: -7px;
}

.form-white-input {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    border: 0px !important;
}

@media (max-width: 991px) {
    .normal-bank-per-section-box > .first {
        min-width: 130px;
        max-width: 130px;
        width: 130px;
    }

    .deposit-normal-bank-list-container > div {
        height: unset;
    }

        .deposit-normal-bank-list-container > div img {
            height: unset !important;
        }
}

.normal-deposit-wrapper {
    margin-top: 20px;
}

.deposit-input-group {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
}

.currency-prefix {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    background: linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    padding: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    height: 100%;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
}

    .currency-prefix:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

#transfer .form-white-input {
    height: 50px;
    border-radius: 8px;
}

#internal-transfer .form-white-input {
    height: 45px;
    border-radius: 8px;
}

#withdrawal-section .form-white-input {
    height: 50px;
    border-radius: 8px;
}

    #withdrawal-section .form-white-input.amount {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

#withdrawal-section .amount-reminder {
    margin-left: 0;
}

.deposit-and-claim-box .second div:first-child {
    margin-bottom: 5px;
}

.deposit-and-claim-box .second > div {
    width: 100%;
}

.normal-bank-selected-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}

.instant-provider-selected-wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
}


.instant-provider {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 60px;
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    background: white;
    opacity: 0.7;
    position: relative;
    padding: 10px;
}

    .instant-provider .tick-image {
        position: absolute;
        top: -8px;
        right: -10px;
        width: 20px;
    }

.instant-provider-selected-wrapper .active {
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.1);
    border: 3px solid #3369FF;
    opacity: 1;
}

.instant-provider-image {
    max-height: 100%;
    max-width: 100%;
    cursor: pointer;
}

.withdrawal-name-alert {
    font-weight: bold;
}

.color-white {
    color: white;
}

.font-12 {
    font-size: 12px;
}

.amount-row button:disabled {
    cursor: not-allowed;
}

#internal-transfer .amount-row {
    grid-template-columns: repeat(3, 1fr);
    padding-top: 20px;
}

.deposit-qr-image-wrapper {
    width: 100%;
    height: 100%;
    max-height: 400px;
}

.deposit-qr-image {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: left;
    width: 100%;
    height: 100%;
    min-height: 300px;
    margin-top: 10px;
}


.instant-provider-reminder {
    display: flex;
    align-items: center;
}

    .instant-provider-reminder .amount-limit-reminder {
        color: #ff0000;
        font-weight: bold;
        font-family: 'averta-bold';
        letter-spacing: 0.5px;
        border-right: 2px solid #898F97;
        padding-right: 20px;
    }

    .instant-provider-reminder .currency-reminder {
        padding-left: 20px;
    }

.provider-search-bar {
    height: 40px;
}
    .provider-search-bar .form-white-input {
        border-radius: 8px;
    }

.flex-direction-column {
    flex-direction: column;
}

.how-to-deposit {
    background: rgba(0, 26, 61, 1);
}

.deposit-tutorial .css-2b097c-container {
    min-height: 45px !important;
    background: transparent !important;
}

.modal-X-btn-1 {
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 2rem;
    color: white;
    font-weight: bold;
}

.tutorial-row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 3px;
    font-size: 14px;
    color: rgb(164, 164, 164);
}

.tutorial-content-wrapper {
    padding-bottom: 20px;
}

.desktop-copy-success-alert {
    position: fixed;
    top: 2%;
    right: 12%;
    width: 150px;
    z-index: 9999;
}

/* .slick-list {
    overflow: unset;
} */

    .slick-list .tick-image {
        position: absolute;
        z-index: 100;
        top: -15px;
        right: -15px;
    }

.align-items-center {
    align-items: center;
}


.recommended-match-container {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    gap: 20px;
    cursor: pointer;
    font-family: Montserrat;
}


.recommended-match-details {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../assets/img/v3/selected_recommended_match_background.png");
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border: 0px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 200px;
}

    .recommended-match-details .topbar {
        font-size: 12px;
        color: white;
        display: grid;
        padding: 10px 20px;
        grid-template-columns: 35% 30% 35%;
    }

        .recommended-match-details .topbar .vendor-logo img {
            width: 70px;
            height: 15px;
        }

        .recommended-match-details .topbar .date-time {
            text-align: end;
        }

    .recommended-match-details .live {
        text-align: center;
        font-size: 14px;
    }

        .recommended-match-details .live .dot {
            height: 10px;
            width: 10px;
            background-color: red;
            border-radius: 50%;
            display: inline-block;
            margin-right: 5px;
        }

    .recommended-match-details .match {
        display: grid;
        grid-template-columns: 40% 20% 40%;
        text-align: center;
        margin-top: 15px;
        color: white;
    }

        .recommended-match-details .match .team-logo img {
            width: 60px;
            height: 60px;
        }

        .recommended-match-details .match .team-logo .team-name {
            text-align: center;
            font-size: 12px;
            display: block;
            margin: 5px 0px;
        }

        .recommended-match-details .match .team-logo .team-score {
            text-align: center;
            font-size: 20px;
            color: #FFD928;
            display: block;
            margin: 5px 0px;
        }

        .recommended-match-details .match .versus {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
        }

.recommended-match-button {
    color: white;
    text-align: center;
    background-color: dodgerblue;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    width: 100%;
    padding: 10px;
    border: 0px;
}

.testButton {
    width: 5%;
    float: right;
    margin-top: -10px;
    margin-right: 10px;
}

.gamepoint-content .title {
    font-size: 20px;
    line-height: 25px;
    color: #fff;
    margin-left: 20px;
}

.gamepoint-content .content {
    display: flex;
    align-items: center;
    column-gap: 13px;
    color: #3369FF;
    font-size: 15px;
}

.gamepoint-content .remarks {
    display: flex;
    align-items: center;
    column-gap: 12px;
    color: #fff;
    font-size: 12px;
    margin-left: 20px;
}

.gamepoint-content .example {
    display: flex;
    align-items: center;
    column-gap: 15px;
    color: #fff;
    font-size: 12px;
    margin-left: 5px;
}

.game-point-description-lbl {
    margin-top: 2px;
    margin-right: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
}

#custom-modal .modal-content {
    background: linear-gradient(180deg, rgba(51, 105, 255, 0.24) -6.16%, rgba(27, 42, 84, 0.24) 110.96%);
    background: #001a3d;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.5), inset 0px 1.5px 0.5px rgba(255, 255, 255, 0.5);
    max-height: 90%;
    border-radius: 65px 65px 65px 65px;
}

    #custom-modal .modal-content::-webkit-scrollbar {
        display: none;
    }

#custom-modal .modal-body {
    padding: 40px 20px 40px;
    max-width: 500px;
    margin: auto;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(255, 51, 75, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%);
    -webkit-backdrop-filter: blur(35px);
    backdrop-filter: blur(35px);
    border-radius: 65px 65px 65px 65px;
    border: 0px;
    width: 100%;
}

.custom-modal-title {
    color: #EFEFEF;
    font-size: 35px;
    font-family: "poppins-semibold";
}

.special-rollover-container {
  background: linear-gradient(to bottom, rgba(27, 42, 84, 0.24), rgba(51, 105, 255, 0.24));
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
  padding: 15px 40px;
  border-radius: 8px;
  margin-top: 100px !important;
}

.special-rollover-container h1 {
  font-size: 15px;
  color: white;
}

.special-rollover-container .icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 3px;
}

.special-rollover-container .inner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3px;
}

.special-rollover-container .inner-container.playable {
  background: linear-gradient(to bottom, rgba(27, 42, 84, 0.24), rgba(51, 105, 255, 0.24));
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
  border-radius: 18px;
  padding: 7px 14px;
}

.special-rollover-container img {
  width: 25px;
  height: 25px;
}

.special-rollover-container .inner-container img {
  opacity: 0.3;
}

.special-rollover-container .inner-container.playable img {
  opacity: 1;
}

.special-rollover-container .inner-container span {
  opacity: 0.3;
}

.special-rollover-container .inner-container.playable span {
  opacity: 1;
}

.special-rollover-container .progress-bar-container {
  margin-top: 12px;
}

.special-rollover-container .progress-bar-outline {
  padding: 2px;
  height: 100%;
  width: 100%;
  background: #404881;
  border-radius: 5px;
}

.special-rollover-container .progress-bar-outline span {
  color: white;
  font-size: 15px;
  left: 50%;
  font-weight: 900;
}

.special-rollover-container .progress-bar-fill {
  background: #3369FF;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
}

.special-rollover-container .rollover-text {
  margin-top: 10px;
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  color: white;
  column-gap: 5px;
  font-weight: 700;
}
.daily-big-div {
    display: flex;
    flex-direction: column;
    grid-row-gap: 20px;
    height: 100%;
    justify-content: flex-start;
}

.daily-check-in {
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    grid-column-gap: 40px;
    justify-content: space-evenly;
}

.daily-check-in-div {
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    cursor: pointer;
}

.daily-check-in-div-1 {
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    grid-column-gap: 30px;
}

.daily-div {
    display: flex;
    align-items: center;
    flex-direction: row;
    grid-column-gap: 30px;
}

.check-in-big-div {
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 5%;
    padding-right: 5%;
    color: rgba(255, 255, 255, 0.87);
    cursor: pointer;
}

.check-in-click {
    display: flex;
    flex-direction: column;
    grid-row-gap: 10px;
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    top: 138px;
    left: 12px;
    border-radius: 8px;
    padding: 15px 15px;
    color: rgba(255, 255, 255, 0.87);
}

.div-togerther {
    display: flex;
    align-items: center;
    justify-content: center;
}

.img-checkin {
    position: absolute;
}

.img-div {
    position: absolute;
    background-color: #02214a;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 60px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.87);
}

.img-coin {
    width: 50px;
    padding-bottom: 10px;
}

.div-check-box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gallery {
    margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery img {
        width: 50%;
        height: auto;
        padding-top: 20px;
    }

.point-div {
    background: linear-gradient(90deg, #295EAC 0%, #314F7D 100%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 80%;
    height: 15px;
    border-radius: 8px 8px 0px 0px;
    color: rgba(255, 255, 255, 0.87);
    /* padding: 5px; */
    text-align: center;
    font-size: 11px;
}

.desc-div {
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 100%;
    height: 30px;
    border-radius: 0px 0px 8px 8px;
    color: rgba(255, 255, 255, 0.87);
    padding: 5px;
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
    font-weight: bold;
}

.daily-check-in-day {
    display: flex;
    padding-left: 35px;
}

.gallery-check-in {
    margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 1.5px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in img {
        padding-top: 50%;
        width: 40px;
    }

.check-in-button {
    width: 100%;
    height: 40px;
    top: 315px;
    left: 22px;
    border-radius: 8px;
    background: rgba(51, 105, 255, 1);
}

.check-in-text {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.check-in-text-disable {
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0px;
    color: white;
}

.video-data {
    display: flex;
    justify-content: center;
    padding: 10px 20px;
}

.video-data-text {
    color: #898F97;
    font-size: 12px;
    font-weight: 400;
}

.days-today-text {
    color: rgba(51, 105, 255, 1) !important;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: center;
    align-self: center;
}

@media(max-width: 1200px) {
    .scroll {
        padding: 4px;
        width: 100%;
        height: 150px;
        overflow: auto;
        white-space: nowrap;
    }

    .daily-check-in-day {
        padding-left: 0px !important;
    }
}

#home-banner {
  height: 900px;
}

.promo-banner-slider .slick-slide img {
    max-width: calc(100% - 20px);
    height: 100px;
    opacity: 0.5;
}
.promo-banner-slider .slick-slide.slick-current img {
    height: 125px;
    opacity: 1;
}

.promo-banner-slider .slick-track {
    display: flex;
    align-items: center;
    margin-left: 10px;
    justify-content: center;
    /* width: 100% !important; */
}

.promo-banner-slider .slick-slide {
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

@media (max-width: 900px) {
    .promo-banner-slider .slick-slide img {
        max-width: calc(100% - 25px);
        height: 90px;
    }

    .promo-banner-slider .slick-slide.slick-current img {
        height: 110px;
    }
}

@media (max-width: 800px) {
    .promo-banner-slider .slick-slide img {
        max-width: calc(100% - 30px);
        height: 90px;
    }

    .promo-banner-slider .slick-slide.slick-current img {
        height: 100px;
    }
}

.desktop-vip-filter-container {
    display: flex;
    align-items: center;
}

.vip-level-name {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.1), rgba(25, 35, 47, 0.1)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%);
    box-shadow: 0px 4px 7px 0px #0000004D;
    width: 100%;
    min-height: 50px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px 20px 10px;
    border-radius: 30px;
    cursor: pointer;
}
    .vip-level-name.active {
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
        color: #3369FF;
    }

.promotion-wrapper .promotion-row {
    display: flex;
    margin: 30px 0;
    align-items: stretch;
    justify-content: space-between;
    width: 100%;
}
    .promotion-wrapper .promotion-row .promo-img {
        width: 50%;
        cursor: pointer;
    }
        .promotion-wrapper .promotion-row .promo-img img {
            width: 100%;
        }
    .promotion-wrapper .promotion-row .promo-content {
        width: 50%;
        margin-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
        .promotion-wrapper .promotion-row .promo-content .promo-title {
            color: white;
            font-size: 20px;
            font-weight: bold;
        }
        .promotion-wrapper .promotion-row .promo-content .promo-text {
            color: #cbcbcb;
            font-size: 16px;
            margin-top: 5px;
        }
        .promotion-wrapper .promotion-row .promo-content .promo-btn {
            display: flex;
            align-items: center;
        }
            .promotion-wrapper .promotion-row .promo-content .promo-btn button {
                width: 100%;
                height: 30px;
                border: none;
                border-radius: 5px;
            }
                .promotion-wrapper .promotion-row .promo-content .promo-btn button:focus-visible {
                    outline: none;
                }

            .promotion-wrapper .promotion-row .promo-content .promo-btn .btn-2 {
                margin-left: 10px;
            }

.promo-detail-modal {
    background: #19232F;
}

.custom-cancel-btn {
    background: #FF334B;
    color: white !important;
}

.promo-detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    background: radial-gradient(90.16% 143.01% at 15.32% 21.04%, rgba(15, 36, 94, 0.15) 0%, rgba(242, 1, 29, 0.012) 77.08%, rgba(6, 22, 65, 0.15) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, linear-gradient(180deg, rgba(78, 75, 200, 0.2) 0%, rgba(0, 84, 182, 0) 100%);
    color: white;
}

.promo-detail-modal .modal-body {
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
}

.detail-close-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 40px;
    cursor: pointer;
}
.detail-close-btn {
    font-size: 40px;
    text-align: center;
}

#promotion-detail .detail-img img {
    width: 100%;
}
.promo-detail-header .detail-title {
    font-size: 20px;
    font-weight: bold;
}

#promotion-detail .detail-title {
    color: white;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 0.7px;
    margin-top: 10px;
}

#promotion-detail .detail-content {
    color: #cbcbcb;
    font-size: 16px;
    margin-top: 10px;
}

#header .main-nav .nav-item.parent div {
    color: white;
    font-family: 'averta-bold';
    cursor: pointer;
}

.loading-promo-spinner-wrapper {
    text-align: center;
    padding: 20px 0;
}



.spin-container {
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 1500px;
    user-select: none;
}

.spin-box {
    max-width: 610px;
    min-width: 610px;
    margin: auto;
    position: relative;
    width: 100%;
}

.img-responsive {
    max-width: 100%;
    display: inline-block;
}

.w-100 {
    width: 100%;
}

.spin-bottom {
    position: absolute;
    bottom: -230px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    z-index: 4;
}

.spin-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: transparent;
    border: 0px;
}

    .spin-icon > img {
        max-width: 90%;
    }
/* .spin-icon>img{
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear; 
} */
.spin-frame {
    position: relative;
    z-index: 1;
}

.not-spinning .spin-list-background {
    position: absolute;
    left: 50%;
    top: 21%;
    transform: translate(-50%,0);
    width: 100%;
    text-align: center;
    width: 77%;
}

.not-spinning .prize-name-list {
    position: absolute;
    width: 100%;
    height: 80%;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 50%;
    top: 9%;
    transform: translate(-50%,0);
    padding: 0px !important;
    margin: 0px !important;
}

    .not-spinning .prize-name-list li {
        position: absolute;
        top: 0;
        transform-origin: 50% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        padding-bottom: 6%;
    }

.spinning .spin-list-box {
    animation-name: spin;
    animation-duration: 5000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    position: absolute;
    left: 11.5%;
    top: 21%;
    transform: translate(-50%,0);
    width: 77%;
}

.spinning .prize-name-list {
    position: absolute;
    width: 100%;
    height: 80%;
    border-radius: 50%;
    overflow: unset;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    left: 50%;
    top: -8%;
    transform: translate(-50%,0);
    padding: 0px !important;
    margin: 0px !important;
}

    .spinning .prize-name-list li {
        position: absolute;
        top: 0;
        transform-origin: 50% 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 50%;
        font-size: 18px;
        color: #fff;
        text-align: center;
        padding-bottom: 17%;
    }

.icon1 {
    position: absolute;
    left: -18%;
}

.icon2 {
    position: absolute;
    right: -18%;
}

.icon3 {
    position: absolute;
    width: calc(4% * 8);
    animation: iconFloater 15s infinite;
    transition: ease-in-out .6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 8);
    bottom: -6%;
    right: -19%;
}

.icon4 {
    position: absolute;
    width: calc(4% * 8);
    animation: iconFloater 15s infinite;
    transition: ease-in-out .6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 8);
    bottom: -8%;
    left: -15%;
}

.icon5 {
    position: absolute;
    width: calc(4% * 5);
    animation: iconFloater 15s infinite;
    transition: ease-in-out 0.6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 5);
    top: 40%;
    left: -25%;
}

.icon6 {
    position: absolute;
    width: calc(4% * 3);
    animation: iconFloater 15s infinite;
    transition: ease-in-out 0.6s;
    z-index: 5;
    background: unset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    padding: calc(0.8% * 3);
    bottom: 34%;
    right: -18%;
}

.d-none {
    display: none;
}

body {
    margin: 0px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes iconFloater {
    0% {
        transform: translateY(0%);
    }

    25% {
        transform: translateY(1em);
    }
}

.rainbow-btn {
    /* margin: 50px auto; */
    width: 60%;
    text-align: center;
    position: relative;
    color: #ffffff;
    text-decoration: none;
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    z-index: 2;
    overflow: hidden;
    padding: 4px;
    height: 40px;
    margin: auto;
    position: absolute;
    top: 3%;
    z-index: 5;
    left: 50%;
    transform: translate(-50%, 0);
}

    .rainbow-btn:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 200%;
        height: 100%;
        background: linear-gradient(115deg,#3c0ce9,#485afa,#3143ec,#12bcfe,#44a2ce);
        background-size: 50% 100%
    }

    .rainbow-btn span {
        position: relative;
        z-index: 2;
        padding: 5px 0;
        font-size: 1.1rem;
        text-decoration: none;
        align-items: center;
        background: #a56820;
        border-radius: 3px;
        display: block;
        justify-content: center;
        box-sizing: border-box;
        height: 100%;
        font-size: 15px;
        font-family: Verdana, Geneva, Tahoma, sans-serif;
    }

    .rainbow-btn:focus:before,
    .rainbow-btn:hover:before {
        animation: rainbow-btn .75s linear infinite
    }

@keyframes rainbow-btn {
    to {
        transform: translateX(-50%)
    }
}

/* //new added */
.i8-spin-section {
    text-align: center;
    padding-top: 119px;
    position: relative;
}

.i8-spin-ring {
    position: absolute;
    z-index: 5;
    top: 27px;
    left: -18px;
}

.i8-spin-body {
    width: 350px;
    height: 350px;
    display: inline-block;
    /* animation-name: spin; */
    animation-duration: 5000ms;
    position: relative;
    z-index: 10;
}

.i8-spin-arrow {
    position: absolute;
    width: 82px;
    bottom: 36%;
    right: 20%;
    z-index: 16;
    opacity: .6;
}

.text-b {
    color: #08174f;
}

.text-y {
    color: #f4ee00;
}

.i8-spin-body > div {
    position: absolute;
    font-weight: bold;
    z-index: 4;
    font-size: 25px;
    width: 92px;
}

/* ////with image item/// */
.i8-spin-body .rewards {
    top: 0%;
    right: 37%;
    transform-origin: bottom;
}

.spinwheel-winning-popup-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/*.spinwheel-winning-img {
    width: 100%;
    text-align: center;
    height: 100px;
}
    .spinwheel-winning-img img {
        height: 100%;
    }*/

.spinwheel-winning-btn {
    box-shadow: 0px 5px 30px #3369ffb3;
    margin-top: 10px;
}

    .spinwheel-winning-btn .custom-btn {
        width: 100%;
        border: none;
        background: #4274ff;
        color: white;
        font-size: 20px;
        padding: 5px 10px;
    }


.spin-container-bg-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity by changing the last value (0.5 in this case) */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1002;
}


.i8-bottom {
    position: relative;
    z-index: 15;
    margin-top: -20px !important;
    max-width: 400px;
    margin: auto;
}

.i8-bottom-button {
    position: relative;
    z-index: 10;
    margin-top: -10px !important;
    max-width: 511px;
    margin: auto;
    cursor: pointer;
}

.i8-bottom-left-coin {
    position: absolute;
    z-index: 15;
    width: 81px;
    height: 67px;
    left: 130px;
    top: 470px;
    animation: floatUpDown 2s ease-in-out infinite;
}

.i8-bottom-right-coin {
    position: absolute;
    z-index: 5;
    width: 78px;
    height: 83px;
    top: 470px;
    right: 130px;
    animation: floatUpDown2 2s ease-in-out infinite;
}

.spin-container-close {
    position: absolute;
    top: 50px;
    right: 50px;
    z-index: 999;
    cursor: pointer;
}

@keyframes floatUpDown {
    0%, 100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-5px);
    }
}

@keyframes floatUpDown2 {
    0%, 100% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(0);
    }
}

.spin-container-open {
    position: fixed;
    z-index: 10;
    left: 94%;
    bottom: 12%;
    transform: translate(-50%,-50%);
    display: flex;
    align-items: center;
    height: 100px;
    width: 100px;
    background: url('/img/lucky-wheel-icon.png') no-repeat transparent;
    background-size: contain;
    cursor: pointer;
    animation: popOut 2s ease-in-out infinite;
}


@keyframes popOut {
    0%, 50%, 100% {
        transform: scale(1) translate(-50%,-50%);
    }

    25%, 75% {
        transform: scale(1.1) translate(-50%,-50%);
    }
}

.i8-bottom-remainder {
    position: absolute;
    right: 5px;
    color: #fff;
    font-weight: bold;
    font-size: 70px;
    line-height: 82px;
    top: 0;
    text-align: center;
    width: 102px;
    font-family: "Roboto", sans-serif;
}

.redicon {
    position: absolute;
    background: linear-gradient(90deg, #295EAC 0%, #314F7D 100%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    width: 75%;
    height: 15px;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    font-size: 11px;
    top: -10px;
}

.redicon-text {
    height: 20px;
    color: rgba(255, 255, 255, 0.87);
    text-align: center;
    margin-block-start: auto;
    font-size: 13px;
}

.gallery-today {
    margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    background: linear-gradient(0deg, rgba(25, 35, 47, 0.2), rgba(25, 35, 47, 0.2)), linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
    border: 2px solid rgba(51, 105, 255, 1);
}

    .gallery-today img {
        width: 50%;
        height: auto;
        padding-top: 20px;
    }

    .gallery-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: rgba(51, 105, 255, 1);
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
        font-weight: bold;
    }

.gallery-check-in-today {
    margin: 20px 0px 0px 20px;
    float: left;
    width: 10%;
    background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
    box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
    border: 2px solid rgba(51, 105, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 86.95px;
    height: 105px;
    top: 497px;
    left: 624.55px;
    border-radius: 5px;
    position: sticky;
}

    .gallery-check-in-today img {
        padding-top: 50%;
        width: 40px;
    }

    .gallery-check-in-today > .desc-div {
        background: linear-gradient(221.94deg, rgba(77, 49, 233, 0.4) 0%, rgba(216, 104, 255, 0) 99.53%);
        box-shadow: 0px 1.5px 0.5px 0px rgba(255, 255, 255, 0.1) inset;
        width: 100%;
        height: 30px;
        border-radius: 0px 0px 8px 8px;
        color: rgba(51, 105, 255, 1);
        padding: 5px;
        text-align: center;
        margin-block-start: auto;
        font-size: 13px;
        font-weight: bold;
    }

.rng-girl .rng-girl-hover, .rng-girl:hover img {
    display: none;
}

.detail-content table td {
    border: 1px solid #fff;
    padding: 5px;
}

a.comm-rate-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 10px;
    background-color: #02214a;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.3);
    color: white;
    border-radius: 10px;
}

#product-rate-detail {
    color: white;
    font-weight: bold;
}

.category-slider {
    display: flex;
    align-items: center;
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 10px;
}

    .category-slider::-webkit-scrollbar {
        background: none;
        height: 2px;
    }

    .category-slider::-webkit-scrollbar-thumb {
        background-color: grey;
    }

    .category-slider .category {
        min-width: 100px;
        text-align: center;
        padding: 5px 10px;
        margin: 5px 10px;
        font-size: 15px;
        background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.12) -18.33%, rgba(27, 42, 84, 0.3) 100%, rgba(27, 42, 84, 0.3) 100%), linear-gradient(0deg, rgba(51, 105, 255, 0.1), rgba(51, 105, 255, 0.1));
        box-shadow: 0px 4px 7px 0px #0000004D;
        border-radius: 25px;
        cursor: pointer;
    }

        .category-slider .category.active {
            color: #3369FF;
        }

.detail-content table td {
    border: 1px solid #fff;
    padding: 5px;
}

.product-row {
    display: flex;
    flex-direction: column;
    width: 100%;
}

    .product-row.row-0 {
        margin-top: 15px;
    }

    .product-row .top {
        width: 100%;
        display: flex;
        font-size: 15px;
    }

    .product-row .bottom {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 8px;
    }

        .product-row .bottom .left, .product-row .bottom .right {
            display: flex;
            width: 50%;
            font-size: 13px;
        }

        .product-row .bottom .right {
            justify-content: flex-end;
        }

.cat-title-text {
    margin-left: 5px;
    color: #3369FF;
}

.provider-hot-tag {
    position: absolute;
    top: -5px;
    right: 5px;
    background-color: red;
    padding: 2px 10px;
    font-size: 13px;
    font-weight: bold;
    border-radius: 10px;
    color: white;
}

#header-wrapper .nav-item.parent div {
    color: white;
    font-family: 'averta-bold';
    cursor: pointer;
}
.seven-cols-updated{
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 20px;
    padding: 0px 10px;
}
.seven-cols-updated .provider-item{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px !important;
}
.seven-cols-updated .provider-item .desktop{
    height: 100%;
    display: flex;
    align-items: center;
}
.provider-prod-img{
    /* width: 150px;
    height: 150px; */
}
@media (max-width: 1200px) {
    .seven-cols-updated{
        grid-template-columns: repeat(6, 1fr);
    }
}
@media (max-width: 991px) {
    .seven-cols-updated{
        grid-template-columns: repeat(5, 1fr);
    }
}

#promotion-detail figure.image img {
  max-width: 100%;
}

#promotion-detail .detail-content table td {
  border: 1px solid #fff;
  padding: 5px;
  color: white;
}

.download-app-container {
    margin-bottom: 40px;
    text-align: center;
    border-radius: 10px;
    font-family: "Montserrat";
    color: white;
    display: flex;
    justify-content: space-between;
}

    .download-app-container .download-app-container-left {
        width: 40%;
        padding: 20px;
    }

        .download-app-container .download-app-container-left .download-app-header {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 40px;
        }

        .download-app-container .download-app-container-left .download-app-description {
            font-size: 16px;
            margin-bottom: 40px;
        }

        .download-app-container .download-app-container-left .download-app-link {
            display: flex;
            align-items: center;
            justify-content: space-around;
        }

            .download-app-container .download-app-container-left .download-app-link .download-app-qr-section {
                margin: 0px 10px;
            }

                .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-qr {
                    background: white;
                    border-radius: 10px;
                    margin-bottom: 10px;
                    padding: 15px 15px 10px;
                }

                    .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-qr canvas {
                        height: 100px !important;
                        width: 100px !important;
                    }

                .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-button {
                    background: white;
                    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.25);
                    box-sizing: border-box;
                    border-radius: 50px;
                    font-size: 12px;
                    text-align: center;
                    display: block;
                    padding: 5px 20px;
                    color: #ffffffb4;
                    font-family: "Montserrat";
                    text-transform: unset;
                    width: 100%;
                    cursor: pointer;
                    color: black;
                }

                    .download-app-container .download-app-container-left .download-app-link .download-app-qr-section .download-app-button img {
                        height: 20px;
                    }

    .download-app-container .download-app-container-right {
        width: 50%;
        padding: 0px 20px;
    }

        .download-app-container .download-app-container-right img {
            width: 100%
        }

.referral-code-text {
    color: white;
    font-weight: bold;
    display: flex;
}

    .referral-code-text span {
        color: red;
    }

.referral-code-div {
    color: white;
    float: left;
    font-weight: bold;
}

.whatsapp-or-div {
    display: flex;
    flex-direction: row;
    color: rgba(137, 143, 151, 1);
}

    .whatsapp-or-div:before, .whatsapp-or-div:after {
        content: "";
        flex: 1 1;
        border-bottom: 1px solid;
        margin: auto;
    }

    .whatsapp-or-div:before {
        margin-right: 10px
    }

    .whatsapp-or-div:after {
        margin-left: 10px
    }

.whatsapp-telegram-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.whatsapp-btn-div {
  border: 1.5px solid rgba(51, 105, 255, 1);
  border-radius: 15px;
  padding: 3px;
  cursor: pointer;
  color: #fff;
}

.whatsapp-btn {
    background: linear-gradient(271.54deg, rgba(51, 105, 255, 0.4) -18.33%, #1B2A54 100%),linear-gradient(0deg, rgba(25, 35, 47, 0.5), rgba(25, 35, 47, 0.5));
    box-shadow: 0px 4px 7px 0px rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    font-size: 14px;
    font-family: Montserrat;
    grid-column-gap: 20px;
    padding: 3px 10px;
    font-size: 13px;
}


.seven-cols-updated .provider-item .desktop .coming-soon-icon, .newgames-section .coming-soon-icon, .popular-section .coming-soon-icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    top: -5px;
    right: 0px;
    height: 70px;
    width: 70px;
}

#banner-popup-modal {
    margin: 0 auto;
}

    #banner-popup-modal .banner-popup-modal-body {
        width: auto;
        height: 100vh;
        margin: auto;
        background-color: transparent !important;
        border: unset;
        border-radius: 5px;
        background: green;
    }


        #banner-popup-modal .banner-popup-modal-body img {
            max-height: calc(100vh - 6rem);
            object-fit: contain;
            object-position: center;
        }

.e-Sports {
    position: relative;
    z-index: 1;
}

    .e-Sports:before {
        content: "HOT";
        position: absolute;
        top: -10px;
        left: 2px;
        width: 50px;
        height: 17px;
        background-color: red;
        z-index: 2;
        border-radius: 8px;
        text-align: center;
        line-height: 16px;
        font-weight: 700;
    }

.fireflame {
    max-width: 30px;
    position: absolute;
    top: -20px;
    left: -15px;
    z-index: 4;
}